import React,{useState, useEffect, useRef}  from 'react';
import {useApi} from '@wollo-lib/kpe-context'
import {Container,Menu,Header,Grid, Message, Form, Label, Button} from 'semantic-ui-react'
import useShopId from '../Hooks/useShopId'


import { useHistory,useParams } from 'react-router-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import ShopCommon from './ShopComon';
import ShopPreise from './ShopPreise'
import ShopLabels from './ShopLables'
import ShopDevices from './ShopDevices'
import MailTemplates from './MailTemplates'

const ShopDaten=({})=>
{

    const {apiSelect,apiInsert, apiDelete} =useApi()
    const PaketGroessen=['S','M','L'
    ,'XL','Brief','Paket']
    const shopId=useShopId()
    const [shopDaten,setShopDaten]=useState({LabelFormat:{}})
    const {sub}=useParams()
    const history=useHistory()
 


 

      
   

    useEffect(()=>
    {
        const getShopDaten=async ()=>
        {
            const [shop]= await apiSelect('/db/ShopDaten/'+shopId)
            shop.NextCode=shop.NextCode ? shop.NextCode :1000
            const LabelFormat=shop.LabelFormat ? shop.LabelFormat :{}
            LabelFormat.pageHeight = LabelFormat.pageHeight ? LabelFormat.pageHeight : 297
            LabelFormat.pageWidth = LabelFormat.pageWidth ? LabelFormat.pageWidth : 210
            LabelFormat.sizeText = LabelFormat.sizeText ? LabelFormat.sizeText :12
            LabelFormat.sizeBarcode = LabelFormat.sizeBarcode ? LabelFormat.sizeBarcode :24
            shop.LabelFormat=LabelFormat
            setShopDaten(shop)

       }
       if(shopId )
            getShopDaten()

    },[shopId])


   

  

    const changeShopData=(e,{name,value})=>
    {
        setShopDaten(data=>({...data,[name]:value}))
    }

    
   
 

    return(

        <Container>
            <Menu tabular attached='top'>
                <Menu.Item
                    name='Allgemein'
                    active={sub === 'Common'}
                    onClick={e=>history.push('/ShopDaten/Common')}
                />
                <Menu.Item
                    name='Preise'
                    active={sub==='Preise'}
                    onClick={e=>history.push('/ShopDaten/Preise')}
                />
                <Menu.Item
                    name='Label'
                    active={sub === 'Label'}
                    onClick={e=>history.push('/ShopDaten/Label')}
                />
                <Menu.Item
                    name='Mail'
                    content='Mail'
                    active={sub === 'MailTemplates'}
                    onClick={e=>history.push('/ShopDaten/MailTemplates')}
                />
                
                <Menu.Item
                    name='Geräte'
                    content='Geräte'
                    active={sub === 'Devices'}
                    onClick={e=>history.push('/ShopDaten/Devices')}
                />
           
            </Menu>

            <Switch>
                <Route path='/ShopDaten/Common'>
                    <ShopCommon
                        shopDaten={shopDaten}
                        changeShopData={changeShopData}
                    />
                </Route>

                <Route path='/ShopDaten/Preise'>
                    <ShopPreise
                        shopId={shopId}
                    />
                </Route>
                <Route path='/ShopDaten/MailTemplates'>
                    <MailTemplates
                        shopDaten={shopDaten}
                        changeShopData={changeShopData}
                    />
                </Route>

                <Route path='/ShopDaten/Label'>
                    <ShopLabels
                        shopDaten={shopDaten}
                        changeShopData={changeShopData}
                    />
                </Route>
                <Route path='/ShopDaten/Devices'>
                    <ShopDevices
                          shopId={shopId}
                    />
                </Route>


            </Switch>

       
                
        </Container> 
    )
}

export default ShopDaten
