import React,{useState, useEffect, useRef}  from 'react';
import { useHistory } from 'react-router';
import {Segment,Header,  Button,  Grid, Checkbox, Icon}  from 'semantic-ui-react'
import Abholung from './Abholung'
import currency from 'currency.js'
import {showDatum} from '@wollo-lib/wollo-react'
import {useApi,useUser} from '@wollo-lib/kpe-context'


const AktPakete=({kunde,reload,setReload,modalAbholungOpen,setModalAbholungOpen,signature,ws})=>
{

    const {apiSelect}=useApi()

    const wochePreis=useRef(0)
    const [zuschlag,setZuschlag]=useState([])
    const [pakete,setPakete]=useState([])
    const [locks, setLocks]=useState({})
    const history=useHistory()

    const {ShopId:shopId}=useUser()




   
    const abholung=()=>
    {
        ws.current.send(JSON.stringify(
            {
                __type:'broadcast',
                message:{
                    __type:'sign',
                    kunde:kunde,
                    pakete:pakete,
                    zuschlag:zuschlag
                }
            }
        ))
        setModalAbholungOpen(true)
    }


    function editPaket(e,{uid})
    {
            history.push('/show/paket/'+uid)
    }

    const selectPaket=(e,{name})=>
    {
        const myPakete=pakete.map((paket,Index)=>(Index===name ? {...paket,Ausliefern: !paket.Ausliefern} : paket))
        zuschlagBerechnen(myPakete)
        setPakete(myPakete)
    }
 
    useEffect(()=>{
        const getPakete= async()=>
        {
            const myPakete= await apiSelect(`/db/PaketeKunde/${shopId}/${kunde.UID}`,'back')
            const my2Pakete=myPakete.map(paket=>({...paket,Ausliefern:true}))
            setPakete(my2Pakete)
     
            const myPreise= await apiSelect(`/db/Preise/${shopId}`)
            const myWochePreis= myPreise.filter(el=>el.Position==='Lagergebühr')
            if(myWochePreis.length)
                wochePreis.current=myWochePreis[0].Preis
            zuschlagBerechnen(my2Pakete)
        }
        getPakete()
    },[ kunde,reload])

    useEffect(()=>{
       
      
        if(pakete.length>0)
        {
            
            const myLocks={}
            pakete.forEach(paket=>{
                if(paket.UIDLock)
                {
                    if(myLocks[paket.UIDLock])
                        myLocks[paket.UIDLock].push(paket)
                    else
                        myLocks[paket.UIDLock]=[paket]
                }
            })
            setLocks(myLocks)
        }
    },[pakete])

    

    const zuschlagBerechnen=(myPakete)=>
    {
        let LagerZeit=0
        myPakete.filter(el=>el.Ausliefern).forEach(paket=>{
            const zeit=Math.floor(Date.now()/1000)-paket.Datum
            LagerZeit= LagerZeit>zeit ? LagerZeit : zeit
        })
        let LagerTage= Math.floor(LagerZeit/3600/24)-1 // der erste Tag zählt nicht als Lagertag
        LagerTage= LagerTage <0 ? 0 : LagerTage // keine negativen Lagertage bei Abholung am selben Tag
        const LagerWochen= Math.floor(LagerTage/7)      
        if(LagerWochen)
        // Zuschlag berechnen
        {
            
            if(wochePreis.current>0)
            {
                setZuschlag([{
                    Position:'Zuschlag Lagergebühr',
                    Anzahl:`${LagerWochen} Wochen`,
                    EPreis:`${currency(wochePreis.current,{symbol:'',separator:"'",decimal:"."}).format()} €/Woche`,
                    Preis: currency(wochePreis.current).multiply(LagerWochen).value
                }])
            }
        }
        else
        {
            setZuschlag(zuschlaege=>(zuschlaege.filter(z=>z.Position!=='Zuschlag Lagergebühr')))
        }
    }


    
 

    return(
    <>
        <Header as="h4">Paketliste (Pakete für Abholung markieren)</Header>
        <Segment>
            <Header as= "h4"> Pakete im Regal</Header>
            <Grid>
                {
                    pakete.filter(paket=>paket.UIDLock===null).map((paket,Index)=>(
                    <Grid.Row >
                        <Grid.Column key={'c'+Index} width={3}>
                                <Checkbox toggle checked={paket.Ausliefern} name={Index} onChange={selectPaket}/>
                        </Grid.Column>
                        <Grid.Column key={'a'+Index} width={3}>
                                {paket.Dienst} 
                        </Grid.Column>
                        <Grid.Column key={'b'+Index} width={4}>     
                            {paket.Paketnummer} 
                        </Grid.Column>
                            <Grid.Column key={'d'+Index} width={2}>     
                            {showDatum(paket.Datum)} 
                        </Grid.Column>
                        <Grid.Column key={'e'+Index} width={2}>     
                            {paket.Size} 
                        </Grid.Column>
                        <Grid.Column key={'f'+Index} width={1}>     
                            <Icon name='edit' basic uid={paket.UID} onClick={editPaket}/>
                        </Grid.Column>
                    </Grid.Row>
                    ))
                }
          
            </Grid>
        </Segment>
        <Segment>
            <Header as= "h4"> Pakete in Boxen</Header>
           
                {
                    Object.values(locks).map(lockPakete =>
                    (<>
                        <Header as= "h4">Box {lockPakete[0].Name}: </Header>
                        <Grid>
                        {
                            lockPakete.map((paket,Index)=>(
                                <Grid.Row style={{marginTop:0, marginBottom:0, paddingTop:0,paddingBottom: 0}}>
                                    
                                    <Grid.Column key={'a'+Index} width={3}>
                                            {paket.Dienst} 
                                    </Grid.Column>
                                    <Grid.Column key={'b'+Index} width={4}>     
                                        {paket.Paketnummer} 
                                    </Grid.Column>
                                        <Grid.Column key={'d'+Index} width={2}>     
                                        {showDatum(paket.Datum)} 
                                    </Grid.Column>
                                    <Grid.Column key={'e'+Index} width={2}>     
                                        {paket.Size} 
                                    </Grid.Column>
                                    <Grid.Column key={'f'+Index} width={1}>     
                                        <Icon name='edit' basic uid={paket.UID} onClick={editPaket}/>
                                    </Grid.Column>
                                </Grid.Row>
                            ))
                        }
                        </Grid>
                    </>))
                }
        </Segment>
        <Button content='ausgewählte Pakete ausliefern' color='blue' onClick={abholung}/>
        <Abholung
            open={modalAbholungOpen}
            setOpen={setModalAbholungOpen}
            kunde={kunde}
            pakete={pakete}
            setReload={setReload}
            shopId={shopId}
            zuschlag={zuschlag}
            signature={signature}
            ws={ws}
        />
    </>
    )
}

export default AktPakete
