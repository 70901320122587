import React,{useState, useEffect}  from 'react';
import {Header,Segment, Grid}  from 'semantic-ui-react'
import MyTabulator,{dateFormatter, autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import Transaktion from './Transaktion'
import {useApi} from '@wollo-lib/kpe-context'
import useShopId from '../Hooks/useShopId' 

const Transaktionsliste=({reload,setReload,user,ws})=>
{
    const {apiSelect}=useApi()
    const shopId=useShopId()
    const [transaktionen,setTransaktionen]=useState([])
    const [modalTransaktionOpen, setModalTransaktionOpen]=useState(false)
    const [transaktionSelected,setTransaktionSelected]=useState(null)
    
    useEffect(()=>{

        const getPakete= async()=>
        {
       
            const myTransaktionen= await apiSelect(`/db/TransaktionenKunde/${shopId}/${user.UID}?tage=100`,'back')
            setTransaktionen(myTransaktionen)
        }
        getPakete()
    },[ user,reload])


    
    function cellClickTransaktion (cell) 
    {
        if(cell.getField()==='UID')
        {
            setTransaktionSelected(cell.getValue())
            setModalTransaktionOpen(true)
        }
    }
    
    const columnsTrans=[
    
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'UID', width:50, hozAlign:"center"}, // edit 
        { title: 'Datum', field: 'Datum', width: 200, formatter:dateFormatter,formatterParams:{
            format:{year:"numeric",month:"2-digit", day:"2-digit", hour:"2-digit", minute:"2-digit"}}},
        { title: 'Typ', field: 'Typ', width: 100,  headerFilter:"input"},
        
    ]
    return (   
    <>
        <Segment>
            <Header as="h4">Transaktionsliste</Header>
            <MyTabulator
                index='UID'
                columns={columnsTrans}
                headerFilterPlaceholder="Spalte filtern"
                data={transaktionen}
                onClick={cellClickTransaktion}

        />
        </Segment>
        
        <Transaktion
          open={modalTransaktionOpen}
          setOpen={setModalTransaktionOpen}
          kunde={user}
          setReload={setReload}
          transaktionUID={transaktionSelected}
          ws={ws}
        />
    </>
    )
}
export default Transaktionsliste
