import React,{useState,useEffect} from 'react'
import {Segment,Header,Grid, Message, Form, Label, Button} from 'semantic-ui-react'
import CurrencyInput from '../lib/CurrencyInput'
import {useApi} from '@wollo-lib/kpe-context'

const ShopPreise=({shopId})=>
{
    const {apiSelect,apiInsert,apiDelete}=useApi()
    const [preise,setPreise]=useState({})
    const ZeilePos=(props)=>
    {
        const {pos}=props
        return(<>
            <Grid.Column key={'a'+pos} width={2}/>
            <Grid.Column key={'b'+pos} width={5}>{pos}</Grid.Column>
            <Grid.Column key={'c'+pos} width={4}>
                <Form.Field
                    control={CurrencyInput}
                    value={
                        preise[pos] ? 
                        preise[pos].Preis   
                        : null
                    }
                    onChange={onChange}
                    name={`Preis:${pos}`}
                    placeholder={'Preis '+pos}
                />
            </Grid.Column>
            <Grid.Column key={'d'+pos} width={5}/>
        </>)
    }

    const onChange=(e,{name,value})=>
    {
       const [item,position]=name.split(':')
       const newVal={...preise.position,[item]:value}
       setPreise(p=>({...preise,[position]:newVal}))
    }

    useEffect(()=>
    {
        const getPreise= async ()=>
        {
            const myPreise= await apiSelect(`/db/Preise/${shopId}`)
            const myPreisObject={}
            myPreise.forEach(preis=>{
                myPreisObject[preis.Position]={Preis:preis.Preis,Einheit:preis.Einheit}
            })
            setPreise(myPreisObject)
        }
        if(shopId )
            getPreise()

    },[shopId])

    const submit= async ()=>
    {
        const data=Object.keys(preise).filter(el=>(preise[el].Preis)).map(el=>(
            {
                ShopId:shopId,
                Position:el,
                Einheit: preise[el].Einheit ? preise[el].Einheit : '#',
                Preis: preise[el].Preis
            }
        ))
        await apiDelete('/db/Preise/'+shopId)
        apiInsert('/db/Preise/',data)
    }

    return(
    <Segment>
        <Header as='h2'>Preise</Header>
        <Form>
            <Segment>
                <Header as='h3'>Automatische Verarbeitung</Header>

                <Grid>
                    <ZeilePos pos='S'/>
                    <ZeilePos pos='M'/>
                    <ZeilePos pos='L'/>
                    <ZeilePos pos='XL'/>


                </Grid>
            </Segment>
            <Segment>
                <Header as='h3'>Manuelle Verarbeitung</Header>
                <Grid>
                    <ZeilePos pos='Brief'/>
                    <ZeilePos pos='Paket'/>
                    <ZeilePos pos='Lagergebühr'/>
                </Grid>
            </Segment>
        </Form>
        <Button color="green" icon='save' content='speichern' onClick={submit}/>
    </Segment>
    )
}
export default ShopPreise
