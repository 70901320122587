import React,{useState, useEffect}  from 'react';
import {useApi} from '@wollo-lib/kpe-context'
import {Segment,Header}  from 'semantic-ui-react'
import MyTabulator,{dateFormatter, autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import ShowMail from '../Mails/ShowMail'
 


const Mails=({kunde,mailListStatus})=>
{
    const {apiSelect}=useApi()
    const [mails,setMails]= useState([])
    const [modalOpen,setModalOpen]=useState(false)
    const [mailSelected,setMailSelected]=useState(0)


    useEffect(()=>
    {
        const getMails= async()=>
        {
            if(kunde)
            {
                const myMails=await apiSelect(`/db/MailsKunde/${kunde}`,'base')
                setMails(myMails)
            }
        
        }
        getMails()

    },[kunde,mailListStatus])

    

   

   function cellClick (cell) 
    {
        if(cell.getField()==='id')
        {
            setMailSelected(cell.getRow().getPosition())
            setModalOpen(true)
        }
    }

    const columns=[

        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'id', width:50, hozAlign:"center"}, // edit 
        { title: 'Datum', field: 'Datum', width: 120, formatter:dateFormatter},
        { title: 'Betreff', field: 'Betreff', width: 600,  headerFilter:"input"},
        { title: 'Status', field:'Status', width: 200, headerFilter:"select"}
        
    ]

  
    

    return (
    <>
        <Segment>
            <Header as="h4">Liste der Mails</Header>
            <MyTabulator
                index='UID'
                columns={columns}
                headerFilterPlaceholder="Spalte filtern"
                data={mails}
                onClick={cellClick}
    
            />
        </Segment>
        <ShowMail
          open={modalOpen}
          setOpen={setModalOpen}
          mail={mails[mailSelected] ? mails[mailSelected] :{} }
        

        />
           

       

        
    </>
    )

}
export default Mails

