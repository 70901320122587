import React,{useEffect,useState} from 'react'
import currency from 'currency.js'
import {Input} from 'semantic-ui-react'
//import PropTypes from 'prop-types'



const CurrencyInput = ({onChange,value,...inputProps}) => {
 
  const [string,setString]=useState('')

  const handleBlur=(e)=>
  { 

    //https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        // You can invoke a callback or add custom logic here
         if(onChange)
          onChange(e, {name:inputProps.name, value:currency(string.replace('.',','),{symbol:'',separator:"'",decimal:","}).value})
      }
    }, 0);
   

  }

  const handleChange=(e,{value})=>
  {
    setString(value)

  }

  useEffect(()=>{
    setString(value ? currency(value,{symbol:'',separator:"'",decimal:"."}).format():'') // this valiue comes in us format from mysql
  },[value])


  return <Input 
            {...inputProps}
            icon='euro'
            onChange={handleChange}
            value={string}
            onBlur={handleBlur}
        />
}



export default CurrencyInput
