
import React,{ useState, useEffect, useRef }  from 'react';
import {Container,Button,Segment,Header} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'
import MyTabulator,{dateFormatter, dateEditor, dateFilter, downloadDateAccessor,autoNumFormatter} from '@wollo-lib/wollo-tabulator'

import EditTemplate from './EditTemplate'
import ShowMail from './ShowMail'
import useShopId from '../Hooks/useShopId' 



const MailList=({mailUri})=>
{
    

    const {apiSelect}=useApi()


  //  const [users,setUsers]=useState([])
    const [mailSelected, setMailSelected]=useState(-1)
    const [modalOpen, setModalOpen]= useState(false)
    const [showOpen,setShowOpen]=useState(false)
    const [mails,setMails]=useState([])
    const shopId=useShopId()


    useEffect(()=>{
        const getMails = async ()=>
        {
            const m=await apiSelect('/db/MailJournal/'+shopId,'base')
 
            setMails(m)
            
          }
        if(shopId)
            getMails()

    },[shopId])
  


    function cellClick (cell) 
    {
        let row=cell.getRow()
        if(cell.getField()==='UID')
        {
            setMailSelected(row.getPosition())
            setShowOpen(true)
        }
    }
   

    

    const columns=[
   
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'UID', width:50, hozAlign:"center"}, // edit 
        {  title: 'Datum', field: 'Datum', width: 140, formatter:dateFormatter, formatterParams:{format:'DD.MM.YYYY'},  headerFilter:dateEditor, 
                headerFilterFunc:dateFilter, accessorDownload: downloadDateAccessor, accessorDownloadParams: {format:'DD.MM.YYYY'}},
        { title: 'Betreff', field: 'Betreff', width: 400,  headerFilter:"input"},
        { title: 'Empfänger', field: 'Empfaenger', width: 400}
     
    ]



    return(
    <>
        <Container>
            <Header as ="h1">Mail Journal</Header>
            {/*
            <Button color='green' icon='plus' content='neue Mailvorlage' onClick={(e)=>{setMailSelected(-1); setModalOpen(true)}}/>
            */}
            <MyTabulator
                index='UID'
                columns={columns}
                headerFilterPlaceholder="Spalte filtern"
                data={mails}
                onClick={cellClick}
                pagination="local"
                height="800px"
        
             />
        </Container>
        <EditTemplate
          open={modalOpen}
          setOpen={setModalOpen}
          mails={mails}
          mailSelected={mailSelected}
          setMails={setMails}
          shopId={shopId}
        />
        <ShowMail
          open={showOpen}
          setOpen={setShowOpen}
          mail={mailSelected >=0 ? mails[mailSelected] :{Betreff:'',Text:''}}
        />

    </>
    )
}
export default MailList
