import React,{ useState, useEffect, useRef }  from 'react';
import {useHistory} from 'react-router-dom'
import {Container,Button,Header,Grid} from 'semantic-ui-react'
import MyTabulator,{autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import ModalAddUser from './ModalAddUser'
import EditMail from '../Mails/ModalEditMail'
import useShopId from '../Hooks/useShopId' 
import {useApi,usePageSelect} from '@wollo-lib/kpe-context'


const UserList=({})=>
{
    const {apiSelect}=useApi()
    const [userSelected, setUserSelected]=useState(-1)
    const [modalOpen, setModalOpen]= useState(false)
    const [modalMailOpen, setModalMailOpen]= useState(false)
    const [users,setUsers]=useState([])
    const [usersFiltered,setUsersFiltered]=useState([])
    const history=useHistory()
    const shopId=useShopId()

    const userList=useRef(null)
    
    

   
    const replaceMode=usePageSelect({select:'/db/Users/'+shopId,urlPara:{}, setData:setUsers, pageSize:200})


 /*   useEffect(()=>{
        const getUsers = async ()=>
        {
            const u=await apiSelect('/db/Users/'+shopId)
             setUsers(u)
        }
        if(shopId)
            getUsers()

    },[ shopId])*/
  
    useEffect(()=>
    {

        if(modalMailOpen)
            setUsersFiltered(userList.current.getData("active").map(user=>({UID:user.UID,Name:`${user.Vorname} ${user.Nachname}`,email:user.email})))
        else
            setUsersFiltered([])

    },[modalMailOpen])

    function cellClick (cell) 
    {
        const data=cell.getData()
        const row=cell.getRow()
        if(cell.getField()==='UID')
        {
            setUserSelected(row.getPosition())
            setModalOpen(true)
        }
        else
        {
            history.push(`/show/kunde/Aktuell/${data.UID}`)
        }
    }
 
   

    const columns=[
   
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'UID', width:50, hozAlign:"center"}, // edit 
        { title: 'Vorname', field: 'Vorname', width: 120,  headerFilter:"input"},
        { title: 'Nachname', field: 'Nachname', width: 120,  headerFilter:"input"},
        { title: 'Plz', field: 'PLZ', width: 100,  headerFilter:"input"},
        { title: 'Ort', field:'Ort', width: 200, headerFilter:"input"},
        { title: 'Land', field: 'Land', width: 50,  headerFilter:"input"},
        { title: 'email', field: 'email',   width: 200},  
        { title: 'Festnetz', field: 'Festnetz',   width: 200 },    
        { title: 'mobil', field: 'mobil',   width: 200 },
    ]



    return(
    <>
        <Container>
             <Grid>
                <Grid.Row>
                    <Header as ="h1">Kundenliste&nbsp;&nbsp;&nbsp;</Header>
                    <Button icon='mail' onClick={e=>{setModalMailOpen(true)}}/>
                </Grid.Row>
            </Grid>
            <Button color='green' icon='plus' content='neuer Kunde' onClick={(e)=>{setUserSelected(-1); setModalOpen(true)}}/>
            <MyTabulator
                index='UID'
                columns={columns}
                headerFilterPlaceholder="Spalte filtern"
                data={users}
                onClick={cellClick}
                height="750px"
                tabRef={userList}
                compact="very"
                replaceMode={replaceMode}
                striped

        
             />
        </Container>
        <ModalAddUser
          open={modalOpen}
          setOpen={setModalOpen}
          users={users}
          userSelected={userSelected}
          setUsers={setUsers}
          shopId={shopId}
        />
         <EditMail
          open={modalMailOpen}
          setOpen={setModalMailOpen}
          shopId={shopId}
          adressaten={usersFiltered}

        />

    </>
    )
}
export default UserList
