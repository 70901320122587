import React,{ useState, useEffect, useRef }  from 'react';
import {useApi} from '@wollo-lib/kpe-context'
import {Modal,Form, Button,Header,Segment} from 'semantic-ui-react'
import Editor from '@wollo-lib/wollo-ckeditor'
import {useCheckForm} from '@wollo-lib/wollo-react'
import {v1 as uuid} from 'uuid'



const EditMailTemplate=({open,setOpen,shopId, adressaten, UID,invalidateMails})=>{
    
    // Adressaten ist eine Url über die man die Adressaten laden kann
    const {apiSelect,apiInsert}=useApi()

    const [mail,setMail]=useState(
        {
            UID: uuid(),
            Datum:Date.now()/1000,
            Betreff:'',
            Text:'',
            ShopId:shopId

        })
    
    const {errors,checkSubmit,hasError}=useCheckForm(mail,
        [
          {name:'Betreff',error:'Es muss ein Betreff der Mail angegeben werden',test:((val)=>(val!==''))},  
          {name:'Text',error:'Es muss eine Mailtext angegeben werden',test:((val)=>(val!==''))}
        ]
   )
    
    
   
    // wenn UID gesetzt, dann mail mit der UID laden, ansonsten UID generieren
    useEffect(()=>
    {
        const loadTemplate= async ()=>
        {
            const temp=apiSelect('/db/MailTemplate/'+UID)
            setMail(temp[0])
        }
        if(UID)
            loadTemplate()
    },[UID])

   
    const handleChange=(e,{name,value, result})=>
    {
        setMail(u=>({...u,[name]:value}))
    }

    const closeModalSubmit=async  ()=>
    {
        if(!checkSubmit())
            return
       
        setOpen(false)
        const myMail={...mail}
        myMail.Datum=Date.now()/1000
        await Promise.all([
            apiInsert('/db/EmailTemplate',myMail,'base'),
            apiInsert('/db/EmailQueue',adressaten.map(to=>({EmailTemplate:mail.UID,Empfaenger:to.UID,Status:'versenden',Datum:Date.now()/1000})),'base')
        ])
        if(invalidateMails)
            invalidateMails(Date.now())
        setMail((mail)=>( {...mail,UID:uuid()}))
    }

    
   

    return (
        <>
        <Modal 
            open={open}           
            onClose={e=>setOpen(false)}
        >
            <Modal.Header>E-Mail Senden</Modal.Header>
            <Modal.Content>
            <Header as="h4">Mail Empfänger</Header>
            <Segment>
            {
                adressaten.map(user=>(`${user.Name} <${user.email}>` )).join(', ')
            }
            </Segment>
            <Form>
                <Form.Input
                    label='Betreff'
                    name='Betreff'
                    placeholder='Betreff'
                    value={mail.Betreff}
                    onChange={handleChange}
                    error={errors['Betreff']}
                />
                <Form.Field
                    label='Text'
                    name='Text'
                    value={mail.Text}
                    onChange={handleChange}
                    control={Editor}
                    error={errors['Text']}
                />
                
            </Form>
            </Modal.Content>
            <Modal.Actions>

                <Button
                    negative
                    icon='close'
                    labelPosition='right'
                    content='Abbrechen'
                    icon='cross'
                    onClick={e=>setOpen(false)}
                />
                <Button
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    icon='send'
                    content='Senden'
                    onClick={closeModalSubmit}
                    />

            </Modal.Actions>
      
    </Modal>
                
 
    </>   
    )
    
}
export default EditMailTemplate




