import React,{ useState, useEffect, useRef }  from 'react';
import { Modal,Button} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const PdfPakete=({open,setOpen,shopId,paketDienst})=>{
    
    const {apiSelect}=useApi()

    const [pdf,setPdf]=useState()
    const [pakete,setPakete]=useState([])

   
     useEffect(()=>{
        const getPakete = async ()=>
        {
            const p=await apiSelect('/db/TagesPakete/'+shopId+"/"+paketDienst )
              setPakete(p)
            
          }
        if(shopId)
            getPakete()

    },[shopId, paketDienst,open])

    
    useEffect(()=>{
        if(open)
        {
            const doc=new jsPDF()
            
            function addHeaders(text) 
            {
                doc.setFont('helvetica', 'bold')
                doc.setFontSize(12)
                const pageCount = doc.internal.getNumberOfPages();
                for(var i = 0; i < pageCount; i++) {
                    doc.text(text,10,10);
                }
                doc.setFont('helvetica', 'italic')
                doc.setFontSize(8)
                for(var i = 0; i < pageCount; i++) {
                    doc.text(`Seite ${i+1} von ${pageCount}`,90, 287);
                }

            }


            doc.autoTable({
                            margin:{top:20,left:10,right:10,bottom:20},
                            head: [['', 'Paketnummer', 'Kunde', 'Absenderkennung']],
                            body: pakete.map((line,Index)=>([Index+1,line.Paketnummer,line.Kunde, line.AbsenderK]))
                          }
                        )
            addHeaders(`${paketDienst} Paketliste vom ${ new Date().toLocaleString('de-DE',{year:"numeric",month:"2-digit", day:"2-digit"})}`)
            setPdf(doc)
            
        }
        else
        {
            setPdf(null)
        }

    },[open,pakete])




   
   
   
    return( 
    <Modal 
        open={open}           
        onClose={e=>setOpen(false)}
    >
      
        <iframe src={pdf ? pdf.output('datauristring') : null} width='100%' height='800rem'/>
        <Modal.Actions>

            <Button
                negative
                icon='close'
                labelPosition='right'
                content='Abbrechen'
                onClick={e=>setOpen(false)}
            />
           
        </Modal.Actions>
      
    </Modal>
    )
    
}
export default PdfPakete
