import React from 'react';
import {Header,Container, Grid}  from 'semantic-ui-react'
import {showDatum} from '@wollo-lib/wollo-react'
import currency from 'currency.js'






export const PaketListe=({pakete,zuschlag,signature,datum, kunde})=>
{

    let Summe=currency(0,{symbol:'',separator:"'",decimal:"."})
    pakete.filter(el=>el.Ausliefern).forEach(paket=>
    {
        Summe=Summe.add(paket.Preis)

    })
    zuschlag.forEach(z=>
    {
        Summe=Summe.add(z.Preis)
    })
    return (
    <>
        <Header as ="h2" >Abholung {kunde.Vorname} {kunde.Nachname} {showDatum(datum)} </Header>
        <Header as ="h3">Paketliste Regal:</Header>

        <Grid>
            {
                pakete.filter(paket=>paket.Ausliefern).map((paket,Index)=>(
                    <Grid.Row>
                        <Grid.Column key={'a'+Index} width={3}>
                                {paket.Dienst} 
                        </Grid.Column>
                        <Grid.Column key={'b'+Index} width={6}>     
                            {paket.Paketnummer} 
                        </Grid.Column>
                            <Grid.Column key={'d'+Index} width={4}>     
                            {showDatum(paket.Datum)} 
                        </Grid.Column>
                        <Grid.Column key={'p'+Index} width={3}>     
                            {paket.Preis ? currency(paket.Preis,{symbol:'',separator:"'",decimal:"."}).format():''} € 
                        </Grid.Column>
                    </Grid.Row>
                ))
                
            }
        <Header as ="h3">Paketliste Boxen:</Header>
            {
                pakete.filter(paket=>paket.Ausliefern).map((paket,Index)=>(
                        <Grid.Row>
                            <Grid.Column key={'a'+Index} width={3}>
                                    {paket.Dienst} 
                            </Grid.Column>
                            <Grid.Column key={'b'+Index} width={6}>     
                                {paket.Paketnummer} 
                            </Grid.Column>
                                <Grid.Column key={'d'+Index} width={4}>     
                                {showDatum(paket.Datum)} 
                            </Grid.Column>
                            <Grid.Column key={'p'+Index} width={3}>     
                                {paket.Preis ? currency(paket.Preis,{symbol:'',separator:"'",decimal:"."}).format():''} € 
                            </Grid.Column>
                        </Grid.Row>
                    ))
                }
            {  zuschlag.length===0 ? '' :
                zuschlag.map((zuschlag,Index)=>(
                    <Grid.Row>
                        <Grid.Column key={'a'+Index} width={3}>
                                {zuschlag.Anzahl} 
                        </Grid.Column>
                        <Grid.Column key={'b'+Index} width={6}>     
                                {zuschlag.Position} 
                        </Grid.Column>
                            <Grid.Column key={'d'+Index} width={4}>     
                            {zuschlag.EPreis} 
                        </Grid.Column>
                        <Grid.Column key={'p'+Index} width={3}>     
                            {currency(zuschlag.Preis,{symbol:'',separator:"'",decimal:"."}).format()} € 
                        </Grid.Column>
                    </Grid.Row>
                ))
                
            }
            
            <Grid.Row>
                    <Grid.Column key={'a'} width={9}/>
                    <Grid.Column key={'d'} width={4}>     
                        Gesamtbetrag
                    </Grid.Column>
                    <Grid.Column key={'p'} width={3}>     
                        { Summe.format()} € 
                    </Grid.Column>
            </Grid.Row>

        
        </Grid>
        
        <Header style={{"margin-top":"30mm"}} as="h3" > Gailingen, den {showDatum(datum)}</Header>
        <div dangerouslySetInnerHTML={{ __html: signature}} />  
    </>

    )
}

class PrintArea extends React.Component {
    render()
    {
        
        return(
            <Container>
    
                <PaketListe {...this.props} />


            </Container>
        )

    }
 }
 export default PrintArea
