import React,{ useState, useEffect, useRef }  from 'react';
import { Button ,Modal} from 'semantic-ui-react'
import {v1 as uuid} from 'uuid'

import EditUser from '../EditUser/EditUser'


const ModalAddUser=(props)=>{
    
    
    const {open,setOpen,users,setUsers,userSelected,shopId}=props
    const [user,setUser]=useState(
        {
            UID:null,
            Vorname:'',
            Nachname:'',
            Strasse:'',
            Strasse2:'',
            PLZ:'',
            Ort:'',
            Land:'CH',
            mobil:'',
            Festnetz:'',
            email:null,
            attr:{},
            Modus:'manuell',
            certified:'personal',
            ShopId:shopId,


        }
    )

   


    const afterSubmit=()=>
    {
        setOpen(false)
    }


    useEffect(()=>{
        if(userSelected<0)
        {
            setUser(    {
                UID:uuid(),
                Vorname:'',
                Nachname:'',
                Strasse:'',
                Strasse2:'',
                PLZ:'',
                Ort:'',
                Land:'CH',
                mobil:'',
                Festnetz:'',
                email:'',
                attr:{},
                Modus:'manuell',
                certified:'personal',
                ShopId:shopId,
        })
        }
        else
        {

                setUser(users[userSelected])

        }
    },[userSelected,users.length])

    return( 
    <Modal 
        open={open}           
        onClose={e=>setOpen(false)}
    >
        <Modal.Header>Benutzer ändern</Modal.Header>
        <Modal.Content>

            <EditUser
                user={user}
                setUser={setUser}
                afterSubmit={afterSubmit}
            />
        </Modal.Content>
        <Modal.Actions>

            <Button
                negative
                icon='close'
                labelPosition='right'
                content='Abbrechen'
                onClick={e=>setOpen(false)}
            />

        </Modal.Actions>
      
    </Modal>
    )
    
}
export default ModalAddUser
