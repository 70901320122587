import React from 'react'
import {Segment,Header,Grid, Message, Form, Label, Button} from 'semantic-ui-react'
import {useCheckForm} from '@wollo-lib/wollo-react'
import {useApi} from '@wollo-lib/kpe-context'


const ShopCommon=({shopDaten,changeShopData})=>
{
    const {apiInsert}= useApi()
    const {errors,checkSubmit,}=useCheckForm(shopDaten,
        [
            {name:'ShopName',error:'Name muss angegeben werden',test:((val)=>(val!==''))},
            {name:'CodePraefix',error:'Code Praefix muss zwischen 2 und 3 Zeichen lang sein',test:((val)=>(val  && (val.length===2 || val.length===3 )))}
        ]
    )

    const submit=()=>
    {
        if(!checkSubmit())
            return
        apiInsert('/db/ShopDaten/',{...shopDaten,UID:shopDaten.ShopId})
    }

    return(
        <Segment>
        <Header as='h2'>Shop Daten</Header>
        <Form>
            <Form.Input 
                label='Shop Name'
                name='ShopName'
                value={shopDaten['ShopName']}
                onChange={changeShopData}
                error={errors['ShopName']}
            />
            <Form.Input 
                label='Code Praefix für Labels'
                name='CodePraefix'
                value={shopDaten['CodePraefix']}
                onChange={changeShopData}
                error={errors['CodePraefix']}
            />
              <Form.Input 
                label='Start Nummer für Labels'
                name='NextCode'
                value={shopDaten['NextCode']}
                onChange={changeShopData}
                error={errors['NextCode']}
                step="1" 
                lang="de-DE" 
                type="number"
                
            />
        </Form>
        <Button color="green" icon='save' content='speichern' onClick={submit}/>
    </Segment>
   
    )
}
export default ShopCommon
