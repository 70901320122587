

import React,{ useState, useEffect, useRef }  from 'react';
import {useUser} from '@wollo-lib/kpe-context'
import {Modal,Form, Button} from 'semantic-ui-react'
import Editor from '@wollo-lib/wollo-ckeditor'
import {useCheckForm} from '@wollo-lib/wollo-react'


import {v1 as uuid} from 'uuid'



const EditMailTemplate=({open,setOpen,mails,setMails,mailSelected,shopId})=>{
    
    const {apiInsert}= useUser()
    const [mail,setMail]=useState(
        {
            UID: null,
            Datum:Date.now()/1000,
            Betreff:'',
            Text:'',
            ShopId:shopId

        })
    
        const {errors,checkSubmit,hasError}=useCheckForm(mail,
        [
          {name:'Betreff',error:'Es muss ein Betreff der Mail angegeben werden',test:((val)=>(val!==''))},  
          {name:'Test',error:'Es muss eine Mailtext angegeben werden',test:((val)=>(val!==''))}
        ]
   )
    
    



   
    const handleChange=(e,{name,value, result})=>
    {
        setMail(u=>({...u,[name]:value}))
    }

    const closeModalSubmit=()=>
    {
        if(!checkSubmit())
            return

        if(mailSelected>=0)
        {
            setMails(mails=>
                (
                    mails.map((m,Index)=>
                        (Index===mailSelected ? mail : m))
                )
            )
        }
        else
        {
            setMails([...mails, {
                    UID: null,
                    Datum:Date.now()/1000,
                    Betreff:'',
                    Text:'',
                    ShopId:shopId
                }])
        }
        apiInsert('/db/Mails/',mail)
        setOpen(false)
    }

    useEffect(()=>{
        if(mailSelected<0)
        {
            setMail( mail=>({
                ...mail,
                UID:uuid()
         
        }))
        }
        else
        {
            setMail({...mails[mailSelected]})
        }
    },[mailSelected,mails])

   

    return (
        <>
        <Modal 
            open={open}           
            onClose={e=>setOpen(false)}
        >
            <Modal.Header>Benutzer ändern</Modal.Header>
            <Modal.Content>
            <Form>
                <Form.Input
                    label='Betreff'
                    name='Betreff'
                    placeholder='Betreff'
                    value={mail.Betreff}
                    onChange={handleChange}
                    error={errors['Betreff']}
                />
                <Form.Field
                    label='Text'
                    name='Text'
                    value={mail.Text}
                    onChange={handleChange}
                    control={Editor}
                    error={errors['Text']}
                />
                
            </Form>
            </Modal.Content>
            <Modal.Actions>

                <Button
                    negative
                    icon='close'
                    labelPosition='right'
                    content='Abbrechen'
                    onClick={e=>setOpen(false)}
                />
                <Button
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='OK'
                    onClick={closeModalSubmit}
                    />

            </Modal.Actions>
      
    </Modal>
                
 
    </>   
    )
    
}
export default EditMailTemplate




