
import React,{useState, useEffect}  from 'react';
import jsPDF from 'jspdf'
import {useApi} from '@wollo-lib/kpe-context'
import { Modal,Button,Form} from 'semantic-ui-react'
import bwipjs from "bwip-js"

const ExtraLabels=({open,setOpen,shopId})=>{
    
    
    const {apiSelect,apiUpdate}=useApi()
    const [shopDaten, setShopDaten]=useState({})
    const [pdf,setPdf]=useState()
    const [actCodes,setActCodes]=useState([])
    const [noOfPages,setNoOfPages]=useState(1)
   

    


    useEffect(()=>
    {
        const getShopDaten=async ()=>
        {
            const myShopDaten= await apiSelect(`/db/ShopDaten/`+shopId)
            const daten=myShopDaten[0]
            setShopDaten(daten)
            const myActCodes= await apiSelect('/db/LabelCodes/'+myShopDaten[0].CodePraefix)
            setActCodes(myActCodes)
       }
        if(shopId )
            getShopDaten()

    },[shopId])



   const genPages=async ()=>
   {
        const doc=new jsPDF()
        let codeStart= shopDaten.NextCode
        const {labelCols,labelRows,marginVertical,marginHorizontal,pageWidth,pageHeight,marginTop,marginLeft,sizeBarcode,sizeText}=shopDaten.LabelFormat
        const praefix=shopDaten.CodePraefix
        const offset=0
        const colWidth=(pageWidth-2* marginHorizontal)/labelCols
        const rowHeight=(pageHeight -2* marginVertical)/labelRows
        
        const generateBarcodes= (codes)=> {
            const images = [];
            let canvas = document.createElement("canvas");
            codes.forEach((text)=>
            {
                try
                {
                    bwipjs.toCanvas(canvas,
                    {
                            bcid: 'code128', // Barcode type
                            text: text, // Text to encode
                            height: 10,
                            scale: 3, // 3x scaling factor
                            includetext: true,            // Show human-readable text
                            textxalign:  'center',        // Always good to set this
                    })
                    images.push(canvas.toDataURL("image/png"));
                }
                catch(e)
                {
                    console.log(e)
                }
            })
            return images
        }

    

        const codes=[]

        for(let i=0; i< noOfPages*labelCols * labelRows; ++i)
        {
            while(actCodes.includes(i+offset+codeStart)) 
            {
                ++offset
            }
            codes.push(praefix+(i+offset+codeStart))
        }

        const images=generateBarcodes(codes)
        images.forEach((image,index)=>
        {
            const pageLabelNo=index% (labelCols * labelRows)
            if (index > 0 && pageLabelNo  === 0)
            {
                doc.addPage();
            }

            const x1=marginHorizontal+marginLeft+(pageLabelNo% labelCols * colWidth)
            const y1=marginVertical+marginTop+ Math.floor(pageLabelNo /labelCols)*rowHeight
            doc.addImage(image,'PNG',x1,y1,40,20)

        })

    
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
        // safe codeStart for next generation sequence
        setShopDaten((daten)=>({...daten,NextCode:codeStart+images.length}))
        apiUpdate(`/db/LabelStartCode/${shopId}/${codeStart+images.length}`)
        

   }





   
   
   
    return( <>
    <Modal 
        open={open}           
        onClose={e=>setOpen(false)}
    >
        <Modal.Content>
            <Form>
                <Form.Input
                    label='Anzahl Seiten:'
                    value={noOfPages}
                    onChange={(e,{value})=>{setNoOfPages(parseInt(value))}}
                    step="1" 
                    lang="de-DE" 
                    type="number"
                />
            </Form>

        </Modal.Content>
        <Modal.Actions>

            <Button
                negative
                icon='close'
                labelPosition='right'
                content='Abbrechen'
                onClick={e=>setOpen(false)}
            />
             <Button
                positive
                icon='print'
                labelPosition='right'
                content='Drucken'
                onClick={e=>{genPages(); setOpen(false)}}
            />
           
        </Modal.Actions>
      
    </Modal>

    </>)
    
}
export default ExtraLabels

