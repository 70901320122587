import React,{ useState, useEffect }  from 'react';
import { Dropdown,  Menu, Icon, Message, Item, Grid} from 'semantic-ui-react'
import {SearchSelect,ResponsiveMenuItems} from '@wollo-lib/wollo-react'
import {useHistory} from "react-router-dom";
import PdfPakete from './PdfPakete/PdfPakete'
import ExtraLabels from './ShopDaten/ExtraLabels'



import useShopId from './Hooks/useShopId' 



const SearchItem=(props)=>
{
    const  {shopId,searchWidth}=props
    const history=useHistory()
    const renderSearch=({ title,value })=> (
        <Item  key={value} style={{color:'black'}} >{title}</Item>
    )
    const handleChange=(e,{value,result})=>
    {
        if(result.typ==='Kunde')
            history.push(`/show/kunde/Aktuell/${value}`)
        else if(result.typ==='Paket')
            history.push(`/show/paket/${value}`)
    }
    
    return (
        <Menu.Item key={99} position='right' >
            <SearchSelect
                style={{"width":searchWidth}}
                fluid
                category
                resultRenderer={renderSearch}
                name='search' 
                onChange={handleChange} 
                placeholder={'Kundenname oder Paketnummer'}
                Value2TitleUrl={'/db/SuggestKunde' }
                SuggestUrl={{Kunde:'/db/SearchKunde/'+shopId,Paket:'/db/SearchPaket/'+shopId}} 
                api='back'
                autoClear
                autoSelect
            />
        </Menu.Item>
    )
}


const MainMenu=(props)=>
{
 
    const [open,setOpen]=useState(false)
    const [labelOpen,setLabelOpen]=useState(false)
    const shopId=useShopId()
    const [paketDienst,setPaketDienst]=useState('DHL')
    const history=useHistory()
    const printPdf=(e,{name})=>
    {
        setPaketDienst(name)
        setOpen(true)
    }

    const printExtraLabels=(e,{name})=>
    {
        setLabelOpen(true)
    }

   
  
    return (
 
    <Menu attached='top' color='yellow' inverted>
        <ResponsiveMenuItems withTreshold={800} >
            <Menu.Item icon='home' onClick={()=>window.location.href='/'}/>
            <Dropdown item simple text='Neu'>
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={el=>{history.push('/new/paket')}}
                            key={'paket'}
                        >
                            Paket

                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={el=>{history.push('/new/kunde')}}
                            key={'user'}
                        >
                            Kunde

                    </Dropdown.Item>
                </Dropdown.Menu>

            </Dropdown>   
            <Dropdown item simple text='Anzeige'>
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={el=>{history.push('/kunden')}}
                            key={'kunden'}
                        >
                        Kunden

                    </Dropdown.Item>
                             
                    <Dropdown.Item
                        onClick={el=>{history.push('/pakete')}}
                            key={'pakete'}
                        >
                        Pakete

                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={el=>{history.push('/transaktionen')}}
                            key={'transaktionen'}
                        >
                        Transaktionen

                    </Dropdown.Item>
                     <Dropdown.Item

                        onClick={el=>{history.push(`/Mails`)}}
                            key={'mails'}
                        >
                        E-Mail Journal

                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={el=>{history.push(`/ShopDaten/Common`)}}
                            key={'shop'}
                        >
                        Shop Daten

                    </Dropdown.Item>
                 
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown item simple text='Drucken'>
                <Dropdown.Menu>
                    <Dropdown.Item
                            onClick={printPdf}
                                key={'ListeBrief'}
                                name='Brief'
                            >
                            Brief Liste

                        </Dropdown.Item>
                      <Dropdown.Item
                        onClick={printPdf}
                            key={'ListeBrief2'}
                            name='Brief2'
                        >
                        Bief Ettikett  Liste
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={printPdf}
                            key={'ListeExpress'}
                            name='DHL-Express'
                        >
                        Express Liste

                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={printPdf}
                            key={'Liste1'}
                            name='DHL'
                        >
                        DHL Liste

                    </Dropdown.Item>
                             
                    <Dropdown.Item
                        onClick={printPdf}
                            key={'Liste2'}
                            name='PRIME'
                        >
                       PRIME Liste

                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={printPdf}
                            key={'Liste2'}
                            name='GLS'
                        >
                       GLS Liste

                    </Dropdown.Item>
 
                    <Dropdown.Item
                        onClick={printPdf}
                            key={'Liste3'}
                             name='Hermes'
                        >
                        Hermes Liste

                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={printPdf}
                            key={'Liste4'}
                             name='DPD'
                        >
                        DPD Liste

                    </Dropdown.Item>
                     <Dropdown.Item
                        onClick={printPdf}
                            key={'Liste5'}
                             name='UPS'
                        >
                        UPS Liste

                    </Dropdown.Item>

                     <Dropdown.Item
                        onClick={printPdf}
                            key={'ListeFedex'}
                             name='FEDEX'
                        >
                        Fedex Liste

                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={printExtraLabels}
                            key={'Label'}
                        >
                        Extra Ettiketten

                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Menu.Item href='//mail.kpe.de' target='_blank' content='Web-Mail' icon='mail' />
                            
        </ResponsiveMenuItems>
        <SearchItem {...props} shopId={shopId} searchWidth='260px' />  
        {/*Modal Dialogs*/ }
        <PdfPakete
            open={open}
            setOpen={setOpen}
            shopId={shopId}
            paketDienst={paketDienst}
        />
        <ExtraLabels
            open={labelOpen}
            setOpen={setLabelOpen}
            shopId={shopId}
        />
    </Menu>

    )
}
export default MainMenu
