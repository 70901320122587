import React,{ useState, useEffect }  from 'react';


import {Segment,Header,List, Label,Grid, Message, Icon, Input,Form, Card, Button, Accordion, Modal} from 'semantic-ui-react'
import {useApi,useUser,use} from '@wollo-lib/kpe-context'

import {useCheckForm/*, SearchSelect*/} from '@wollo-lib/wollo-react'
import {SearchSelect} from '@wollo-lib/wollo-react'
import {v1 as uuid} from 'uuid'
import {useHistory,useLocation,} from 'react-router-dom'
import useShopId from '../Hooks/useShopId' 







const EditUser=({user,setUser,afterSubmit})=>
{
    const {apiSelect,apiInsert,apiDelete}=useApi()
    const history=useHistory()
    const shopId=useShopId()
    const path=useLocation().pathname.split('/')

  /*  useEffect(()=>{
        const checkUnique=async ()=>
        {
            const anderePerson=await apiSelect(`/db/EmailUnique/${user.UID}/${user.email}`)
            if(anderePerson && anderePerson.length)
            {
                setUser(user=>({...user,EmailUnique:false}))
            }
            else
            {
                setUser(user=>({...user,EmailUnique:true}))
            }

        }
        if(user.email || user.email==='')
            checkUnique()
        else
            handleChange({},{name:'EmailUnique', value:true})
    },[user.email,user.UID])*/
    const {errors,checkSubmit,hasError}=useCheckForm(user,
        [
          {name:'Vorname',error:'Vorname muss angegeben werden',test:((val)=>(val!==''))},  
          {name:'Nachname',error:'Nachname muss angegeben werden',test:((val)=>(val!==''))},
          {name:'Land',error:'Land muss angegeben werden',test:((val)=>(val!==''))},
          {name:'email',error:'ungültiges E-Mail Adressformat',test:((val)=>(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(val)||!val)) },
       /*   {name:'email',error:'diese E-Mail Adresse ist bereits für einen anderen Kunden erfasst',display:'allways', test:((val)=>(user && user.EmailUnique)) },*/
          {name: 'mobil', error:'Nummer muss im internationalen Format mit +41 usw angegeben werden', test:((val)=>(/^\+(?:[0-9] ?){6,14}[0-9]$/.test(val)||!val))},
          {name: 'Festnetz', error:'Nummer muss im internationalen Format mit +41 usw angegeben werden', test:((val)=>(/^\+(?:[0-9] ?){6,14}[0-9]$/.test(val)||!val))}
        ]
    )
    


    const deleteUser=()=>
    {
        apiDelete('/db/User/'+user.UID)
        history.push('/')
    }

    const submit=()=>
    {
        if(checkSubmit())
        {
            apiInsert('/db/User/',user)
            if(path[1]==='new')
            {
                setUser({
                    UID:uuid(),
                    Vorname:'',
                    Nachname:'',
                    Strasse:'',
                    Strasse2:'',
                    PLZ:null,
                    Ort:null,
                    Land:'CH',
                    mobil:'',
                    Festnetz:'',
                    email:'',
                    attr:{},
                    Modus:'manuell',
                    certified:'personal',
                    ShopId:shopId,


                })
    
            }
            if(afterSubmit)
                afterSubmit()
        }
    }


    const handleChange=(e,{name,value, result})=>
    {
        if(name==='PLZOrt')
        {
            if(result)
            {
                setUser(u=>({...u,
                    Ort:result.Ort,
                    PLZ:result.PLZ,
                    Land:result.Land,
                    OIndex:result.OIndex
                }))
            }
            else
            {
                setUser(u=>({...u,Ort:value}))
            }
        }
       
        else
        {
            setUser(u=>({...u,[name]:value}))
        }
    }



    return(
      <Segment>
            <Form autocomplete={uuid()}>
                <Grid>
                    <Grid.Column key={1} width={8}>
                        <Form.Input
                            label={'Vorn'+String.fromCharCode(8203)+'ame'}
                            placeholder={'Vorn'+String.fromCharCode(8203)+'ame'}
                            value={user.Vorname}
                            onChange={handleChange}
                            name='Vorname'
                        />
                    </Grid.Column>
                    <Grid.Column key={2} width={8}>
                        <Form.Input
                            label={'Nach'+String.fromCharCode(8203)+'name'}
                            placeholder={'Nach'+String.fromCharCode(8203)+'name'}
                            value={user.Nachname}
                            onChange={handleChange}
                            name='Nachname'
                        />
                    </Grid.Column>
                </Grid>
                <Form.Input
                    label='Adresse 1'
                    placeholder={'Stras'+String.fromCharCode(8203)+'se'}
                    value={user.Strasse}
                    onChange={handleChange}
                    name='Strasse'
                />
                <Form.Input
                    label='Adresse 2'
                    placeholder={'Stra'+String.fromCharCode(8203)+'sse/Zusatz'}
                    value={user.Strasse2}
                    onChange={handleChange}
                    name='Strasse2'
                />
                <Form.Field
                    label='Postleitzahl, Ort *'
                    value={(user.PLZ? user.PLZ :'')+  (user.PLZ && user.Ort ?' ':'') + (user.Ort ? user.Ort :'')} 
                    name='PLZOrt' 
                    onChange={handleChange} 
                    placeholder={'Postl'+String.fromCharCode(8203)+'eitzahl und Or'+String.fromCharCode(8203)+'t'}
                    control={SearchSelect}
                    SuggestUrl={'/db/SearchPLZOrt'} 
                    error={errors['Ort']}
                    api='base'
                    autocomplete={uuid()}
                    type='search'
               />
                <Form.Field
                    label='Land *' 
                    value={user.Land} 
                    name='Land' 
                    onChange={handleChange} 
                    placeholder={'La'+String.fromCharCode(8203)+'nd'}
                    control={SearchSelect}
                    Value2TitleUrl={'/db/Land' }
                    SuggestUrl={'/db/SearchLand'} 
                    error={errors['Land']}
                    api='base'
                    autoSelect
                    autocomplete='nein2'

                />
            
                <Form.Input
                    label={'E-'+String.fromCharCode(8203)+'Mail'}
                    placeholder={'E-M'+String.fromCharCode(8203)+'ail Adresse'}
                    value={user.email}
                    onChange={handleChange}
                    name='email'
                    error={errors['email']}
                />
                <Form.Input
                    label={'Festnetz'+String.fromCharCode(8203)+' Telefonnummer'}
                    placeholder={'Telef'+String.fromCharCode(8203)+'on'}
                    value={user.Festnetz}
                    onChange={handleChange}
                    name='Festnetz'
                    error={errors['Festnetz']}
                />
                <Form.Input
                    label={'Mobil'+String.fromCharCode(8203)+' Telefonnummer'}
                    placeholder={'Han'+String.fromCharCode(8203)+'dy'}
                    value={user.mobil}
                    onChange={handleChange}
                    name='mobil'
                    error={errors['mobil']}
                />
                {path[1]==='new' ? '' : <Button icon='trash' content='Löschen' onClick={deleteUser}/>}
                <Button
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='OK'
                    onClick={submit}
                    color={hasError ? 'grey' : 'green'}
                />

            </Form>
        </Segment>
    )
}
export default EditUser
