import React,{ useState,  useRef }  from 'react';


import { Button,  Modal} from 'semantic-ui-react'



import EditUser from '../EditUser/EditUser'


const ModalEditKunde=({open,setOpen,user,setUser})=>
{
   

    const afterSubmit=()=>
    {
        setOpen(false)
    }
   
    return( 
    <Modal 
        open={open}           
        onClose={e=>setOpen(false)}
    >
        <Modal.Header>Benutzer ändern</Modal.Header>
        <Modal.Content>

            <EditUser
                user={user}
                setUser={setUser}
                afterSubmit={afterSubmit}


            />
        </Modal.Content>
        <Modal.Actions>

            <Button
                negative
                icon='close'
                labelPosition='right'
                content='Abbrechen'
                onClick={e=>setOpen(false)}
            />
     

        </Modal.Actions>
      
    </Modal>
    )
    
}
export default ModalEditKunde
