import React,{useRef,useEffect} from 'react'    
import {wsBase} from  '../config'
import {useApi,useUser} from '@wollo-lib/kpe-context'

const useWebsocket=(ws,handleMessage,abo,status=(newStatus)=>{} )=>
{

    const wsPing=useRef(true)
    const interval=useRef()

    const {apiSelect,apiUpdate}=useApi()
    const {ShopId}=useUser()

    const createWebSocket=async ()=>
    {

        if(!ws.current)
        {
            let myDevice=sessionStorage.getItem('device')
            myDevice= myDevice ? myDevice : 'empty'
            const {token,device} = await apiUpdate(`/socket/authKey/${ShopId}/${myDevice}`,{abo:abo},'sign')
            
            sessionStorage.setItem('device',device)
            ws.current =  new WebSocket(`wss://${wsBase}?shop=${ShopId}&token=${token}&device=${device}`);
            wsPing.current = true
            status(true)

        }

        ws.current.onopen=()=>
        {
            console.log('connected');
            wsPing.current=true
        };

        ws.current.onmessage=(message)=>
        {
            //console.log(message);
            if(message.data==='pong')
            {
                wsPing.current=true
            }
            else
            {
                try
                {
                    const msgObject=JSON.parse(message.data)
                    handleMessage(msgObject)
                }
                catch(e) {}
            }
        };
        ws.current.onClose=()=>
        {
            ws.current=null
        }
    }


    useEffect(()=>{
        createWebSocket()
        // start loop which checks connection every minute
        interval.current=setInterval(async () => 
        {
            if(!ws.current)
            {
                // reconnect server

                createWebSocket()
            }
            else 
            {
                let connected={success:false}
                const myDevice=sessionStorage.getItem('device')
                if(myDevice)
                {
                   connected=await apiSelect(`/socket/health/${ShopId}/${myDevice}`,'sign')
                }
            
                // close connection and try to reconnect
                if(!connected.success && ws.current)
                {
                    ws.current.close()
                    ws.current=null
                    status(false)
                }

            }
        }, 10000);
        // close web socket connection, when leaving
        return(()=>{
            if(ws.current)
            {
                if(ws.current)
                {
                    ws.current.close()
                    ws.current=null
                    status(false)
                }
                clearTimeout(interval.current)
            }
        })
    },[])
    return ws
}

export default useWebsocket
