import React,{useState,useEffect, useRef} from 'react'
import {Segment,Modal, Button} from 'semantic-ui-react'
import Tabulator,{autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import {useApi} from '@wollo-lib/kpe-context'
import bwipjs from "bwip-js"
import {v1 as uuid} from 'uuid' 

const ShopDevices=({shopId})=>
{
    const {apiSelect,apiInsert,apiDelete}=useApi()
    const [devices,setDevices]=useState([])
    const [showBarcode,setShowBarcode]=useState(false)
    const [reload,setReload]=useState(0)
    const barcodeCanvas=useRef()

    useEffect(()=>
    {
        const getDevices= async ()=>
        {
            const myDevices= await apiSelect(`/db/Devices/${shopId}`)
            setDevices(myDevices.map(d=>({...d,UID2:d.UID})))
        }
        if(shopId )
            getDevices()

    },[shopId,reload])

    const dataEdited=(data)=>
    {
        console.log(data)
        apiInsert('/db/Devices/'+shopId,data.map(el=>({...el,ShopId:shopId})))
    }

    const displayBarcode=(text)=>
    {
        setShowBarcode(true)
        bwipjs.toCanvas(barcodeCanvas.current,
            {
                    bcid: 'qrcode', // Barcode type
                    text: text, // Text to encode
      //              height: 100,
                    scale: 3, // 3x scaling factor
                    includetext: true,            // Show human-readable text
                    textxalign:  'center',        // Always good to set this
            })
    }

    const cellClick=(cell)=>
    {
        const row=cell.getRow()
        if(cell.getField()==='UID')
        {
            const device=cell.getData()
            displayBarcode(`${device.UID}:${device.Token}`)
        }
        if(cell.getField()==='UID2')
        {
            apiDelete('/db/Device/'+cell.getData())
        }

    }

    const addDevice= async ()=>
    {
        const uid="UUID-"+uuid()
        const deviceData = await apiSelect(`/extraApi/addDevice/${shopId}/${uid}`)
        displayBarcode(`${uid}:${deviceData.token}`)
        setReload(r=>++r)
    }

    const columns=[
   
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        {formatter:(cell, formatterParams)=>('<i class="icon mobile"></i>'), field:'UID', width:50, hozAlign:"center"}, // edit 
        { title: 'ID', field: 'UID', width: 200,  headerFilter:"input"},
        { title: 'Buchstabe', field: 'Prefix', width: 100,  headerFilter:"input"},
        { title: 'Name', field: 'Name', width: 300,  headerFilter:"input", editor:"input"}, 
        {formatter:(cell, formatterParams)=>('<i class="icon trash"></i>'), field:'UID2', width:50, hozAlign:"center", download:false}
    ]

    return(
        <Segment>
            <Tabulator
                index='UID'
                columns={columns}
                headerFilterPlaceholder="Spalte filtern"
                data={devices}
                onClick={cellClick}
                height="650px"
                initialSort={[{column: 'UID', dir:'desc'}]}
                dataEdited={dataEdited}

             />
             <Button content='Neues Gerät hinzufügen' icon='plus' color='green' onClick={addDevice}/>
             <Modal open={showBarcode} size='mini'>
                 <Modal.Header as='h3'>QR-Code scannen (rechtes oberes Menu)</Modal.Header>
                 <Modal.Content>
                    <canvas ref={barcodeCanvas} style={{position:'relative', left: '50%', width: '100px', marginLeft: '-50px'}}></canvas>
                 </Modal.Content>
                 <Modal.Actions>
                     <Button icon='check' color='green' onClick={e=>setShowBarcode(false)}/>
                 </Modal.Actions>
             </Modal>
        </Segment>
    )

}
export default ShopDevices
