import React,{useState, useEffect, useRef}  from 'react';
import ModalEditKunde from './ModalEditKunde'
import useShopId from '../Hooks/useShopId' 
import {Segment,Header,Icon,Container,  Button, Menu, Grid}  from 'semantic-ui-react'


const ShowAdress=({user, setUser, modalOpen, setModalOpen})=>
{
    const shopId=useShopId()

    const openEdit=()=>
    {
        setModalOpen(true)
    }


    return(
    <Segment>
        <div>    
            <p>{user.Strasse}<br/>
            {user.PLZ} {user.Ort}<br/>
            {user.LandLang}</p>
            <p><Icon name="phone"/>{user.Festnetz}<br/>
            <Icon name="mobile alternate"/>{user.mobil}<br/>
            <Icon name="mail"/>{user.email}
            </p>
            <Button circular color='green' onClick={openEdit} icon='edit'/>
        </div>
        <ModalEditKunde
            open={modalOpen}
            setOpen={setModalOpen}
            user={user}
            setUser={setUser}
            shopId={shopId}
        />
    </Segment>


    )
}
export default ShowAdress
