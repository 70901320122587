import React,{useState, useEffect, useRef}  from 'react';
import {useHistory} from 'react-router-dom'
import {showDatum} from '@wollo-lib/wollo-react'
import {useApi} from '@wollo-lib/kpe-context'
import {useReactToPrint} from 'react-to-print';
import PrintArea,{PaketListe} from './PaketListe'
import {autoNumFormatter,dateFormatter} from '@wollo-lib/wollo-tabulator'
import {Button,Modal} from  'semantic-ui-react'



const Transaktion=({open,setOpen,kunde,transaktionUID,setReload,ws})=>
{
    const {apiSelect,apiUpdate,apiDelete}=useApi()
    const printRef=useRef()
    const [pakete,setPakete]=useState([])
    const [transaktion,setTransaktion]=useState({Zuschlaege:[]})
    const history = useHistory()


    useEffect(()=>
    {
        const getTransaktion= async()=>
        {
            if(transaktionUID)
            {
                const myPakete=await apiSelect(`/db/TransaktionPakete/${transaktionUID}`)
                const my2Pakete=myPakete.map(paket=>({...paket,Ausliefern:true}))
                setPakete(my2Pakete)
                const myTransaktion=await apiSelect(`/db/Transaktion/${transaktionUID}`)
                setTransaktion(myTransaktion[0])
                // show on tablet


            }
       
        }
        getTransaktion()

    },[transaktionUID])

    

   const closeStorno= async ()=>
    {
        // define new transaktion
        if(window.confirm('Wirklich diese Transaktion stornieren/zurücksetzen'))
        {
            closeTablet()
            setOpen(false)
                 // nach alle Pakete der Transaktion auf registriert Status zurücksetzen
            await apiUpdate(`/db/PaketeTransaktionStatus/${transaktionUID}/registriert`)
            // Transaktion löschen
            await apiDelete(`/db/Transaktion/${transaktionUID}`)      
            apiDelete(`/db/TransaktionPakete/${transaktionUID}`)

            setReload(r=>++r)
        }


    }

  
    const closeTablet=()=>
    {
        if(ws)
        {
            ws.current.send(JSON.stringify(
                {
                    __type:'broadcast',
                    message:{
                        __type:'close'
                    }
                }
            ))
        }
    }

   function cellClick (cell) 
    {
        if(cell.getField()==='id')
        {
            history.push(`/show/paket/${cell.getValue()}`)
        }
    }

    const columns=[

        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'id', width:50, hozAlign:"center"}, // edit 
        { title: 'Dienst', field: 'Dienst', width: 120,  headerFilter:"input"},
        { title: 'Datum', field: 'Datum', width: 120, formatter:dateFormatter},
        { title: 'Paketnummer', field: 'Paketnummer', width: 300,  headerFilter:"input"},
        { title: 'Absenderkennung', field:'AbsenderK', width: 400, headerFilter:"input"}
        
    ]

    const handlePrint = useReactToPrint(
    {
        content: () => printRef.current,
        pageStyle: ` @page { size: A4;  margin: 20mm; } 
                    @media print {
                         body {
                               width: 210mm;
                               height: 297mm;
                              -webkit-print-color-adjust: exact; 
                        } 
                    }
                `
    });



    useEffect(()=>
    {
        if(open && ws)
        {
            ws.current.send(JSON.stringify(
                {
                    __type:'broadcast',
                    message:{
                        __type:'show',
                        UID:transaktionUID
                    }
                }
            ))
        }

    })

    

    return (
    <>

            <Modal
                open={open} 
                onClose={e=>{setOpen(false); closeTablet()}}

            >


                <Modal.Header>Abholung {kunde.Vorname} {kunde.Nachname} {showDatum(Date.now()/1000)} </Modal.Header>
                <Modal.Content>
                <PaketListe kunde={kunde} pakete={pakete} zuschlag={transaktion.Zuschlaege} datum={transaktion.Datum} signature={transaktion.Signature}/>
            
                <Button content='Paketliste drucken' onClick={handlePrint}/>

                <div  style={/*{ display: "none" }*/{ overflow: 'hidden', height: 0 }}>
                    <PrintArea  
                        ref={printRef} 
                        kunde={kunde} 
                        datum={transaktion.Datum} 
                        zuschlag={transaktion.Zuschlaege} 
                        pakete={pakete} 
                        signature={transaktion.Signature}
                    />
                </div>

                

                </Modal.Content>

                <Modal.Actions>

                   
                    <Button
                        negative
                        icon='trash'
                        labelPosition='right'
                        content='Stornieren'
                        onClick={closeStorno}
                    />
                    <Button
                        positive
                        icon='close'
                        labelPosition='right'
                        content='OK'
                        onClick={e=>{setOpen(false); closeTablet()}}
                    />

                </Modal.Actions>

            </Modal>


        
    </>
    )

}
export default Transaktion
