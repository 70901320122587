import React,{ useState, useEffect, useRef }  from 'react';
import {Container,Button,Segment,Header,Form,Modal} from 'semantic-ui-react'
import {useApi, usePageSelect} from '@wollo-lib/kpe-context'
import { useHistory } from "react-router-dom";
import MyTabulator,{dateFormatter, dateEditor, dateFilter, downloadDateAccessor, downloadTable, autoNumFormatter, searchEditor} from '@wollo-lib/wollo-tabulator'
import EditPaket from '../NeuesPaket/EditPaket'
import {useCheckForm} from '@wollo-lib/wollo-react' 
import {v1 as uuid} from 'uuid'
import useShopId from '../Hooks/useShopId' 




const Pakete=({})=>
{
    const {apiSelect,apiInsert,apiDelete} = useApi()
    const history=useHistory()


  //  const [users,setUsers]=useState([])
    const [paketSelected, setPaketSelected]=useState(
      {
            Kunde:'',
            Dienst:'',
            Datum:Date.now()/1000,
            ShopId:'',
            Paketnummer:'',
            Status:'registriert',
            AbsenderK:'',
            Paketdienst:''
        }
    )

    const shopId=useShopId()

    

    const [modalOpen, setModalOpen]= useState(false)
    const [pakete,setPakete]=useState([])
    const [paketFilter,setPaketFilter]=useState(0)
    const [sisters,setSisters]=useState([])
    const tabRef=useRef()

    const paketFilterOptions=
    [
            {id:'Bestand',text:'Bestand', value:0, filter: {Extra:'registriert', Extra2:'registriert'}},
            {id:'abgeholt',text:'abgeholt', value:1,filter: {Extra:'abgeholt', Extra2:'abgeholt'}},
            {id:'alle',text:'alle Pakete', value:2,filter:{}}
    ]
   
    const replaceMode=usePageSelect({select:'/db/PaketListe/'+shopId,urlPara:paketFilterOptions[paketFilter].filter, setData:setPakete, pageSize:50})
  
    function cellClick (cell) 
    {
        let row=cell.getRow()
        if(cell.getField()==='UID')
        {
            if(window.confirm('wirklich löschen?'))
            {
                setPakete(pp=>pp.filter(el=>el.UID!==cell.getValue()))
                apiDelete('/db/Paket/'+cell.getValue())
            }
        }
        else if (cell.getField()==='KundeName')
        {
            const data=cell.getData()
            history.push(`/show/kunde/Aktuell/${data.Kunde}`)
        }
        else
        {
            const paket=cell.getData()
            setPaketSelected(paket)
            setModalOpen(true)
        }
    }

   const changePaketFilter=(e,{value})=>
   {
       setPaketFilter(value)
   }



    const submitPaket=(e)=>
    {
        if(!checkSubmit())
            return
        setModalOpen(false)
        // existieremdes Paket ?
        if(pakete.find(p=>(p.UID===paketSelected.UID)))
        {
            setPakete(p=>p.map((paket)=>
                (paket.UID===paketSelected.UID ? paketSelected : paket)
                )
            )
        }
        else
        {
            setPakete([paketSelected,...pakete])
        }

        apiInsert('/db/Paket/',paketSelected)
    }

    const neuesPaket=(e)=>
    {
        setPaketSelected (p=>({
            Kunde:'',
            Dienst:p.Dienst,
            Datum:Date.now()/1000,
            ShopId:shopId,
            Paketnummer:'',
            Status:'registriert',
            AbsenderK:'',
            UID:uuid(),
            Size: p.Dienst==='Brief' ? 'Brief' : 'Paket'
        }))
        setModalOpen(true)
    }
   
    const {errors,checkSubmit,hasError}=useCheckForm(paketSelected,
        [
          {name:'Kunde',error:'Kunde angegeben werden',test:((val)=>(val!==''))},
          {name:'Dienst',error:'Dienst muss gewählt werden',test:((val)=>(val!==''))},
          {name:'Paketnummer',error:'Paketnummer muss angegeben werden',test:((val)=>(val!==''))},
        ]
    )
    
    

    const columns=[
   
        {formatter:autoNumFormatter, width:50, frozen:true, download:false}, //incrementing row number
        
        { title: 'Datum', field: 'Datum', width: 140, formatter:dateFormatter, formatterParams:{},  headerFilter:dateEditor, 
                headerFilterFunc:dateFilter, accessorDownload: downloadDateAccessor, accessorDownloadParams: {}}, 
        { title: 'Dienst', field: 'Dienst', width: 100,  headerFilter:"input"},
        { title: 'Status', field: 'Status', width: 100,  headerFilter:"input"},
        { title: 'Kunde', field:'KundeName', width: 200, headerFilter:"input"},
        { title: 'Paketnummer', field: 'Paketnummer', width: 200,  headerFilter:"input"},
        { title: 'Grösse/Art', field: 'Size', width: 120,  headerFilter:"input"},
        {formatter:(cell, formatterParams)=>('<i class="icon trash"></i>'), field:'UID', width:50, hozAlign:"center", download:false}, // edit 
        
    ]



    return(
    <>
        <Container>
            <Header as ="h1">Paketliste</Header>
            <Form>
                <Form.Select
                    placeholder='Anzeigefilter...'
                    fluid
                    lable='Anzeigen:'
                    options={paketFilterOptions}
                    onChange={changePaketFilter}
                    value={paketFilter}
                />
            </Form>
            <Button color='green' icon='plus' content='neues Paket' onClick={neuesPaket}/>
             {/* pagination="local"*/}
            <MyTabulator
                index='UID'
                columns={columns}
                headerFilterPlaceholder="Spalte filtern"
                data={pakete}
                onClick={cellClick}
                replaceMode={replaceMode}
                height="650px"
                tabRef={tabRef}
                initialSort={[{column: 'Datum', dir:'desc'}]}
                compact="very"
                striped
             >
                <Segment>
                    <Button icon='file pdf' content='Liste Drucken' onClick={e=>{downloadTable(tabRef,"pdf", "Paketliste.pdf",{orientation:'portrait'})}}/>
                </Segment>
            </MyTabulator>
            <Modal open={modalOpen}>
            { 
                paketSelected ?
            
                <Modal.Content>
                  <EditPaket 
                    errors={errors}
                    paket={paketSelected}  
                    setPaket={setPaketSelected}
                    shopId={shopId}
                    header='Paket bearbeiten'
                />
               </Modal.Content>
               :''
            }
               <Modal.Actions>
                   <Button color='red' content='Abbrechen' icon='close' onClick={e=>setModalOpen(false)}/>
                   <Button color='green' content='OK' icon='check' onClick={submitPaket}/>
               </Modal.Actions>
            </Modal>
        </Container>
    </>
    )
}
export default Pakete
