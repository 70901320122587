import React,{useState, useEffect, useRef}  from 'react';
import {useHistory,useParams, useLocation, Route, Switch} from 'react-router-dom'
import {useApi} from '@wollo-lib/kpe-context'
import {Segment,Header,Container,  Button, Menu, Grid}  from 'semantic-ui-react'
import useShopId from '../Hooks/useShopId' 
import EditMail from '../Mails/ModalEditMail'
import AktPakete from './AktPakete'
import Mails from './Mails'
import useWebSocket from '../Hooks/useWebSockets'
import TransaktionsListe from './TransaktionsListe'
import ShowAdress from './ShowAdress'


const ShowKunde=({})=>
{
    const {apiSelect,apiDelete}=useApi()
    const {tab}=useParams()
    const path=useLocation().pathname.split('/')
    const UID=path[4]
    const [signature,setSignature]=useState()
    const [user,setUser]=useState({Vorname:'',
                                   Nachname:'',
                                   Strasse:'',
                                   PLZ:'',
                                   Ort:'',
                                   Land:'',
                                   LandLang:'',
                                   Festnetz:'',
                                   mobil:'',
                                   email:'',
                                   Modus:''})



    const history = useHistory()
    const [reload,setReload]=useState(0)

    const [modalOpen,setModalOpen]=useState(false)
    const [modalAbholungOpen,setModalAbholungOpen]=useState(false)
    const [modalMailOpen, setModalMailOpen]=useState(false)   
    const [activeItem,setActiveItem]=useState(tab)
    const [mailListStatus,setMailListStatus]=useState(Date.now())
    const ws=useRef()
    const shopId=useShopId()

   
    const handleSocket=(data)=>
    {
        if(data.__type==='signature')
        {
            setSignature(data.signature)
        }

    }

    useWebSocket(ws,handleSocket,['signature'])





    useEffect(()=>{

        const getUser= async()=>
        {
            const myUser= await apiSelect(`/db/Kunde/${UID}`,'back')
            if(myUser && myUser.length)
            {
                    setUser(myUser[0])
            }
            
        }
        getUser()
    },[UID])

    

    

    function cellClick (cell) 
    {
        if(cell.getField()==='UID')
        {
            history.push(`/show/paket/${cell.getValue()}`)
        }
    }

    
    
    const handleItemClick = (e, { name }) =>
    {
        setActiveItem(name)
        history.push(`/show/kunde/${name}/${UID}`)
    }

    


  
   
    return (
    <>

        <Container>
            <Grid>
                <Grid.Row>
                    {
                        (user.email==='' || user.Strasse==='' || user.PLZ ==='' || (user.Festnetz==='' && user.mobil==='')) ?
                            <Button color='red' content='unvollständige Daten ergänzen' onClick={e=>{setModalOpen(true)}}/> 
                        : ''

                    }
                    <Header as ="h2">{user.Vorname} {user.Nachname}&nbsp;&nbsp;&nbsp;</Header>
                    <Button icon='mail' onClick={e=>{setModalMailOpen(true)}}/>
                </Grid.Row>
            </Grid>
            
   
            <Menu tabular attached='top'>
                <Menu.Item
                    name='Aktuell'
                    active={activeItem === 'Aktuell'}
                    onClick={handleItemClick}
                />
                <Menu.Item
                    name='Transaktionen'
                    active={activeItem === 'Transaktionen'}
                    onClick={handleItemClick}
                />
                 <Menu.Item
                    name='Kundendaten'
                    active={activeItem === 'Kundendaten'}
                    onClick={handleItemClick}
                />
                <Menu.Item
                    content='E-Mails'
                    name='EMails'
                    active={activeItem === 'EMails'}
                    onClick={handleItemClick}
                />
            </Menu>
            <Segment attached='bottom'>
                <Switch>
                    <Route exact path={'/show/kunde/Kundendaten/:UID'}>
                        <ShowAdress 
                            user={user}
                            setUser={setUser}
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}

                        />
                    </Route>
                    <Route exact path={'/show/kunde/Aktuell/:UID'}>

                        <AktPakete 
                            kunde={user}
                            reload={reload}
                            setReload={setReload}
                            modalAbholungOpen={modalAbholungOpen}
                            setModalAbholungOpen={setModalAbholungOpen}
                            signature={signature}
                            ws={ws}
                        />
                    </Route>
                    <Route exact path={'/show/kunde/Transaktionen/:UID'}>
                        <TransaktionsListe
                            reload={reload}
                            setReload={setReload}
                            user={user}
                            ws={ws}
                        />
                        
                    </Route>
                    <Route exact path={'/show/kunde/EMails/:UID'}>
                        <Mails 
                            kunde={UID}
                            shopId={shopId}
                            mailListStatus={mailListStatus}
                        />
                        
                    </Route>
                    
                </Switch>
            </Segment>
                    
        </Container>
        

        <EditMail
          open={modalMailOpen}
          setOpen={setModalMailOpen}
          shopId={shopId}
          adressaten={[{UID:user.UID,Name:`${user.Vorname} ${user.Nachname}`,email:user.email}]}
          invalidateMails={setMailListStatus}

        />



    </>
    )

}
export default ShowKunde
