import React,{useState,useEffect} from 'react'
import {Segment,Header,Grid, Dropdown, Form, Label, Button} from 'semantic-ui-react'
import CKEditor from '@wollo-lib/wollo-ckeditor'
import {useApi} from '@wollo-lib/kpe-context'
import useShopId from '../Hooks/useShopId'
import {v1 as uuid} from 'uuid'

const MailTemplates=({shopDaten,changeShopData})=>
{
    const {apiInsert, apiSelect}= useApi()
    const [selectedMail,setSelectedMail]=useState()
    const shopId=useShopId()
    const [mailTemplates,setMailTemplates]=useState([])
    
    useEffect(()=>
    {
        const getMailTemplates= async ()=>
        {
            const templates= await apiSelect('/db/RobotMails/'+shopId)
            setMailTemplates(templates)

        }
        getMailTemplates()
    },
    [shopId])

    const handleChange=(e,{name,value})=>
    {
        const newMail={...selectedMail}
        newMail[name]=value
        setMailTemplates((temps)=>temps.map(temp=>( temp.UID !== selectedMail.UID ? temp :newMail)))
        setSelectedMail(newMail)
        apiInsert('/db/Mails/',newMail)
    }

    const selectMail=(e,{value})=>
    {
        let mail= mailTemplates.find(el=>(el.Robot===value)) 
        if(!mail)
        {
            mail= {
                Robot:value, 
                UID:uuid(),
                ShopId:shopId,
                Datum: Date.now()/1000,
                Text:'',
                Betreff:'',
            }
            setMailTemplates(temps=>([...temps,mail]))
        }
        setSelectedMail( mail)
    }

    return (
        <Segment>
            <Form>

                <Header as="h4">Mail Vorlagen dieses Shops anpassen:</Header>
                <Form.Field 
                    label='verfügbare Texte' 
                    control={Dropdown}
                    value={selectedMail ? selectedMail.Robot : null} 
                    selection
                    onChange={selectMail}
                    options={[
                        {key:'Pakete Benachrichtigung Mittags',text:"Pakete Benachrichtigung Mittags",value:'PaketeBenachrichtigungMittags'},
                    ]}
                    name='select'
                /> 
                {
                    !selectedMail  ? '' :
                    <>
                        <Form.Input
                            label='Betreff'
                            value={selectedMail.Betreff}
                            onChange={handleChange}
                            placeholder='Betreff'
                            name='Betreff'
                        />
                        <Form.Field
                            label='Mailvorlage'
                            control={CKEditor}
                            value={selectedMail.Text}
                            onChange={handleChange} 
                            name='Text'
                        />
                    </>
                }
            </Form>
        </Segment>

    )
}
export default MailTemplates
