import React,{ useState, useEffect}  from 'react';
import useShopId from './Hooks/useShopId' 
import {Container,Icon,Header} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'

const Home=({})=>
{
    const {apiSelect}=useApi()


    const [shop,setShop]=useState({})
    const shopId=useShopId()

    useEffect(()=>{
        const getShop= async ()=>
        {
            const myShop= await apiSelect('/db/Shop/'+shopId,'base')
            setShop(myShop[0])
        }
        if(shopId)
            getShop()
    },[shopId])

    return(
        <Container>
            <Header as='h1' icon textAlign='center' style={{'margin-top':'200px'}}>
                <Icon name='box' circular/>
                <Header.Content>
                    {shop.ShopName}
                </Header.Content>
            </Header>
        </Container>
    )
}
export default Home
