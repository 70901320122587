import React,{useState, useEffect, useRef}  from 'react';
import {Segment,Header,Container, Form}  from 'semantic-ui-react'
import {SearchSelect} from '@wollo-lib/wollo-react' 
import MyTabulator,{dateFormatter, autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import { useHistory } from 'react-router'
import {useApi} from '@wollo-lib/kpe-context'



const EditPaket=({paket,setPaket,errors,shopId, header})=>
{
    const history=useHistory()
    const [sisters, setSisters]=useState([])
    const {apiSelect}=useApi()



    const handleChange=(e,{name,value,result})=>
    {
        if(name==='Kunde' && value)
        {
            setPaket(p=>({...p,Kunde:value, KundeName: result.title}))
        }
        else if(name==='Dienst')
        {
            setPaket(p=>({...p,Dienst:value, Size: value==='Brief' ? 'Brief' : 'Paket'}))
        }
        else
        {
            setPaket(p=>({...p,[name]:value}))
        }
    }

    useEffect(()=>{
        const getPakete= async()=>
        {
            const mySisters= await apiSelect(`/db/PaketeKunde/${shopId}/${paket.UIDKunde}`,'back')
            setSisters(mySisters)
        }
        if(paket.Kunde)
            getPakete()
        else
            setSisters([])
    },[paket])

    
    const columns=[
   
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        { title: 'Dienst', field: 'Dienst', width: 120,  headerFilter:"input"},
        { title: 'Datum', field: 'Datum', width: 120, formatter:dateFormatter},
        { title: 'Paketnummer', field: 'Paketnummer', width: 300,  headerFilter:"input"},
        { title: 'Produkt/Grösse', field:'Size', width: 150, headerFilter:"input"}
        
    ]

    
    function cellClick (cell) 
    {
            const data=cell.getData()
            history.push(`/show/paket/${data.UID}`)

    }

    return (
    <Container >
        <Segment>
           <Header as="h2">{header}</Header>
           <Form>
                <Form.Field
                        label='Kunde' 
                        value={paket.UIDKunde} 
                        name='Kunde' 
                        onChange={handleChange} 
                        placeholder={'Kundennnamen suchen'}
                        control={SearchSelect}
                        Value2TitleUrl={'/db/SuggestKunde' }
                        SuggestUrl={'/db/SearchKunde/'+shopId} 
                        api='back'
                        error={errors['Kunde']}
                        SuggestMap={(el)=>({key:el.UID,UID:el.value,title:el.title})}
                    />
                <Form.Dropdown
                    label='Paketdienst'
                    value={paket.Dienst}
                    selection
                    options={
                        ['Brief','DHL','DHL-Express','Hermes','DPD','GLS','UPS','FEDEX']. map(el=>({key:el,text:el,value:el}))
                    }
                    placeholder = "Paketdienst wählen"
                    onChange={handleChange}
                    name='Dienst'
                    error={errors['Dienst']}
                />
                <Form.Dropdown
                    label='Produkt/Berechnung'
                    value={paket.Size}
                    selection
                    options={
                        ['Brief','Paket','S','M','L','XL']. map(el=>({key:el,text:el,value:el}))
                    }
                    placeholder = "Art Grösse für Preisberechnung"
                    onChange={handleChange}
                    name='Size'
                    error={errors['Dienst']}
                />
                <Form.Input
                    label='Paketnummer'
                    value={paket.Paketnummer}
                    name='Paketnummer'
                    placeholder='Paketnummer eingeben'
                    error={errors['Paketnummer']}
                    onChange={handleChange}
                />

            </Form>
            { !sisters.length ? '' :
                <>
                    <Header> weitere Pakete dieses Kunden</Header>
                    <MyTabulator
                        index='UID'
                        columns={columns}
                        headerFilterPlaceholder="Spalte filtern"
                        data={sisters}
                        onClick={cellClick}
            
                    />
                </>
            }
        </Segment>
    </Container>
)}
export default EditPaket
