import React,{useState, useRef}  from 'react';
import {useHistory,useParams} from 'react-router-dom'
import {useApi} from '@wollo-lib/kpe-context'
import { Button, Modal}  from 'semantic-ui-react'
import {v1 as uuid} from 'uuid'
import {showDatum} from '@wollo-lib/wollo-react'
import {useReactToPrint} from 'react-to-print';
import PrintArea,{PaketListe} from './PaketListe'


 


const Abholung=({pakete,open,setOpen,kunde,transaktionUID,setReload,shopId,zuschlag,signature,ws})=>
{
    const {apiInsert,apiUpdate}=useApi()
    const {UID,data}=useParams()
    const printRef=useRef()

    const history = useHistory()
    const win=useRef()
 

    const [modalOpen,setModalOpen]=useState(false)


    const closeTablet=()=>
    {
        ws.current.send(JSON.stringify(
            {
                __type:'broadcast',
                message:{
                    __type:'close'
                }
            }
        ))
    }

  



    
   const handlePrint = useReactToPrint(
    {
        content: () => printRef.current,
        pageStyle: ` @page { size: A4;  margin: 20mm; } 
                    @media print {
                         body {
                               width: 210mm;
                               height: 297mm;
                              -webkit-print-color-adjust: exact; 
                        } 
                    }
                `
    });
    

   const closeModalSubmit= async ()=>
    {
        // define new transaktion
        const newTransaktion= 
            {
                UID: uuid(),
                Typ:'Abholung',
                Kunde:kunde.UID,
                Datum:Math.floor(Date.now()/1000),
                ShopId: shopId,
                Zuschlaege:JSON.stringify(zuschlag), 
                Signature: signature
            }
        apiInsert('/db/Transaktion/',newTransaktion)
        if(transaktionUID)
        {
            apiUpdate(`/db/TransaktionNachfolger/${transaktionUID}/${newTransaktion.UID}`)
        }
        const transListe= pakete.filter(el=>(el.Ausliefern)).map(el=>(
            {
                Transaktion:newTransaktion.UID,
                Paket:el.UID,
                Berechnet:el.Preis
            }
        ))
        await apiInsert('/db/TransaktionPakete',transListe)
        // nach alle Pakete der Transaktion auf abgeholt Status setzen
        await apiUpdate(`/db/PaketeTransaktionStatus/${newTransaktion.UID}/abgeholt`)
        setReload(r=>++r)
     //   handlePrint()
        closeTablet()
        setOpen(false)

    }

   
   



    

    return (
    <>

            <Modal 
                open={open}           
                onClose={e=>{setOpen(false);closeTablet()}}
            >


                <Modal.Header>Abholung {kunde.Vorname} {kunde.Nachname} {showDatum(Date.now()/1000)} </Modal.Header>
                <Modal.Content>
                
            
                   <PaketListe kunde={kunde} pakete={pakete} zuschlag={zuschlag} datum={Math.floor(Date.now()/1000)} signature={signature}/>



                <div  style={{ display: "none" }}>
                    <PrintArea  
                        ref={printRef} 
                        kunde={kunde} 
                        pakete={pakete}
                        zuschlag={zuschlag} 
                        datum={Math.floor(Date.now()/1000)}/>
                </div>
                

                </Modal.Content>

                <Modal.Actions>

                    <Button
                        negative
                        icon='close'
                        labelPosition='right'
                        content='Abbrechen'
                        onClick={e=>{setOpen(false); closeTablet()}}
                    />
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content='OK'
                        onClick={closeModalSubmit}
                        />

                </Modal.Actions>


            </Modal>


        
    </>
    )

}
export default Abholung
