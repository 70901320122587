import React,{useState} from 'react'
import {Segment,Header,Grid, Message, Form, Label, Button} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'
import {useCheckForm} from  '@wollo-lib/wollo-react'

const ShopCommon=({shopDaten,changeShopData})=>
{
    const {apiUpdate}=useApi()
    const labelFormat=shopDaten.LabelFormat
    const {errors:errorsLabel,checkSubmit:checkLabelSubmit,}=useCheckForm(labelFormat,
        [
            {name:'pageWidth',error:'Seitenbreite muss >0 sein',test:((val)=>(val>0))},
            {name:'pageHeight',error:'Seitenhöhe muss >0 sein',test:((val)=>(val>0))},
            {name:'labelCols',error:'Anzahl Spalten muss >0 sein',test:((val)=>(val>0))},
            {name:'labelRows',error:'Anzahl Zeilen muss >0 sein',test:((val)=>(val>0))},
        ])
    
    const changeLabelData=(e,{name,value})=>
    {
        changeShopData(e,{
            name:'LabelFormat',
            value:{
                ...labelFormat,
                [name]:parseFloat(value)
            }
        })
    }

    const submit=()=>
    {
        if(!checkLabelSubmit)
            return
        apiUpdate('/db/LabelFormat/'+shopDaten.ShopId,{Format:labelFormat})       
    }
    
    return(
        <Segment>
        <Header as='h2'>Extra Paket Label Format</Header>
        <Form>
            <Header as='h4' content='Grösse des Ettikettenbogens'/>
            <Form.Group  widths='equal'>

                <Form.Input 
                    label='Breite'
                    name='pageWidth'
                    value={labelFormat['pageWidth']}
                    onChange={changeLabelData}
                    error={errorsLabel['pageWidth']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />

                <Form.Input 
                    label='Höhe'
                    name='pageHeight'
                    value={labelFormat['pageHeight']}
                    onChange={changeLabelData}
                    error={errorsLabel['pageHeight']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />

            </Form.Group>
            <Header as='h4' content='Rand des Ettikettenbogens'/>
            <Form.Group  widths='equal'>
                <Form.Input 
                    label='oben/unten'
                    name='marginVertical'
                    value={labelFormat['marginVertical']}
                    onChange={changeLabelData}
                    error={errorsLabel['marginVertical']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />
                <Form.Input 
                    label='rechts/Links'
                    name='marginHorizontal'
                    value={labelFormat['marginHorizontal']}
                    onChange={changeLabelData}
                    error={errorsLabel['marginHorizontal']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />
            </Form.Group>
            <Header as='h4' content='Anordnung der Ettiketten'/>
            <Form.Group  widths='equal'>
                <Form.Input 
                    label='Spalten'
                    name='labelCols'
                    value={labelFormat['labelCols']}
                    onChange={changeLabelData}
                    error={errorsLabel['labelCols']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />
                <Form.Input 
                    label='Zeilen'
                    name='labelRows'
                    value={labelFormat['labelRows']}
                    onChange={changeLabelData}
                    error={errorsLabel['labelRows']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />
            </Form.Group>
            <Header as='h4' content='Positionierung auf den Ettiketten'/>
            <Form.Group  widths='equal'>
                <Form.Input 
                    label='Linker Rand'
                    name='marginLeft'
                    value={labelFormat['marginLeft']}
                    onChange={changeLabelData}
                    error={errorsLabel['marginLeft']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />
                <Form.Input 
                    label='Oberer Rand'
                    name='marginTop'
                    value={labelFormat['marginTop']}
                    onChange={changeLabelData}
                    error={errorsLabel['marginTop']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />
            </Form.Group>
            <Header as='h4' content='Zeichengrösse'/>
             <Form.Group  widths='equal'>
                <Form.Input 
                    label='Barcode Grösse'
                    name='sizeBarcode'
                    value={labelFormat['sizeBarcode']}
                    onChange={changeLabelData}
                    error={errorsLabel['sizeBarcode']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />
                <Form.Input 
                    label='Text Grösse'
                    name='sizeText'
                    value={labelFormat['sizeText']}
                    onChange={changeLabelData}
                    error={errorsLabel['sizeText']}
                    step="0.1" 
                    lang="de-DE" 
                    type="number"
                />
            </Form.Group>

        </Form>
        <Button color="green" icon='save' content='speichern' onClick={submit}/>
    </Segment>
    )
}
export default ShopCommon
