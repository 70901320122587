import React  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,


} from "react-router-dom";

import Home from './Home'
import MainMenu from './MainMenu'
import EditUser from './EditUser/EditSingleUser'
import UserList from './UserList/UserList'
import ShowKunde from './ShowKunde/ShowKunde'
import ShowPaket from './ShowPaket/ShowPaket'
import NeuesPaket from './NeuesPaket/NeuesPaket'
import MailList from './Mails/MailList'
import ShopDaten from './ShopDaten/ShopDaten'
import Pakete from './Pakete/Pakete'
import Transaktionen from './Tansaktionen/TransaktionListe'

const App = () => {

 
    return (
            <Router>
                <MainMenu />
       
                <Switch>
                    <Route path="/new/paket" >
                        <NeuesPaket/> 
                    </Route>            
                    <Route path="/new/kunde" >
                        <EditUser/>
                    </Route>          
                    <Route path="/edit/kunde/:UID" >
                        <EditUser/>
                    </Route>  
                    <Route path="/kunden" >
                        <UserList/>
                    </Route>
                    <Route exact path="/pakete" >
                        <Pakete/>
                    </Route>
                    <Route exact path="/transaktionen" >
                        <Transaktionen/>
                    </Route>
                    <Route path="/show/kunde/:tab" >
                        <ShowKunde/>
                    </Route> 
                    <Route exact path="/show/paket/:PaketId" >
                        <ShowPaket/>
                    </Route>
                    <Route path="/Mails">
                        <MailList/>
                    </Route> 
                    <Route path="/ShopDaten/:sub">
                        <ShopDaten/>
                    </Route>                         
                    <Route>
                        <Home/>               
                    </Route> 
                </Switch>
        </Router>
    )
}


export default App;
