import React,{useState, useEffect, useRef}  from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {Segment,Header,Form,Container, Button}  from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'
import {v1 as uuid} from 'uuid'
import useShopId from '../Hooks/useShopId' 

import EditUser from './EditUser'

const EditSingleUser=({})=>
{
    const {apiSelect}=useApi()
    const path=useLocation().pathname.split('/')
    const {UID}=useParams()
    const history=useHistory()
    const shopId=useShopId()
    const [user,setUser]=useState(
        {
            UID:null,
            Vorname:'',
            Nachname:'',
            Strasse:'',
            Strasse2:'',
            PLZ:null,
            Ort:null,
            Land:'CH',
            mobil:'',
            Festnetz:'',
            email:null,
            attr:{},
            Modus:'manuell',
            certified:'personal',
            ShopId:shopId,


        }
    )


    useEffect(()=>{
        const getUser=async()=>{
            const myUser= await apiSelect(`/db/Kunde/${UID}`,'back')
            setUser(myUser[0])

         
            
        }
        const newUser=async()=>
        {
            setUser(u=> ({...u,UID:uuid(),ShopId:shopId,attr:{}}))

        }
        
        
        if(path[1]==='new')
        {
            newUser()
        }
        else
        {
            getUser()
        }

    },[])





    return(
    <Container text>
        <Header>{(path[1]==='new') ? 'Neuen Kunden anlegen' : 'Kunden ändern'}</Header>
        <EditUser
                user={user}
                setUser={setUser}
         /> 
    </Container>

    )
}

export default EditSingleUser
