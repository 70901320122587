import React,{useState, useEffect}  from 'react';
import {useHistory,useParams} from 'react-router-dom'
import {useApi} from '@wollo-lib/kpe-context'
import {Segment,Header,Container,  Form, Button, Modal}  from 'semantic-ui-react'
import useShopId from '../Hooks/useShopId' 
import {showDatum,SearchSelect} from '@wollo-lib/wollo-react'
import MyTabulator,{dateFormatter, autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import EditPaket from '../NeuesPaket/EditPaket'
import {useCheckForm} from '@wollo-lib/wollo-react' 

const ShowPaket=({paketId})=>
{
    const {apiSelect, apiInsert, apiDelete}= useApi()
    const {PaketId : paketPara}=useParams()
    const PaketId=paketId ? paketId : paketPara
    const [kunde,setKunde]=useState('')
    const [paket,setPaket]=useState({})
    const [sisters, setSisters]=useState([])
    const history=useHistory()
    const shopId=useShopId()
    const [modalOpen, setModalOpen]=useState(false)

       
    const {errors,checkSubmit,hasError}=useCheckForm(paket,
        [
          {name:'Kunde',error:'Kunde angegeben werden',test:((val)=>(val!==''))},
          {name:'Dienst',error:'Dienst muss gewählt werden',test:((val)=>(val!==''))},
          {name:'Paketnummer',error:'Paketnummer muss angegeben werden',test:((val)=>(val!==''))},
        ]
    )
    
    
    useEffect(()=>{

        const getPaket= async()=>
        {
            const myPaket= await apiSelect(`/db/Paket/${PaketId}`,'back')
            if(myPaket.length)
                setPaket(myPaket[0])
        }
        getPaket()
    },[PaketId])

    useEffect(()=>{
        const getPakete= async()=>
        {
            const mySisters= await apiSelect(`/db/PaketeKunde/${shopId}/${paket.Kunde}`,'back')
            setSisters(mySisters)
            const myKunde= await apiSelect(`/db/SuggestKunde/${paket.Kunde}`,'back')
            if(myKunde)
                setKunde(myKunde[0].title)
        }
        if(paket && paket.Kunde)
            getPakete()
        else
            setSisters([])
    },[paket])

    const submit=()=>
    {
        if(!checkSubmit())
            return
        apiInsert('/db/Paket/',paket)
    }


    async function  deletePaket()
    {
        if(window.confirm('soll das Paket wirklich komplett aus dem System gelöscht werden?\n(bitte diese Funktion nur zur Bereinigung von Datenbankleichen verwenden)'))
        {
            await apiDelete('/db/Paket/'+paket.UID)
        }
        history.go(-1)
    }


    return (
        <Container >
            <Segment>
              
                  <EditPaket 
                    errors={errors}
                    paket={paket}  
                    setPaket={setPaket}
                    shopId={shopId}
                    header='Paket bearbeiten'
                />

                <Button icon='save' color='green' content='OK' onClick={submit}/>
                <Button icon='trash' content='Paket löschen' onClick={deletePaket} />
                
            </Segment>
        </Container>
    )

}
export default ShowPaket
