import React,{ useState, useEffect, useRef }  from 'react';
import {useApi} from '@wollo-lib/kpe-context'
import {Modal, Button, Segment, Header, Label} from 'semantic-ui-react'



const ShowMail=({open,setOpen,mail})=>
{

    const {apiSelect}=useApi()
    const [mailTo,setMailTo]=useState([])

    useEffect(()=>{
        const getTo=async ()=>
        {
            const myTo=await apiSelect('/db/QueueTo/'+mail.EmailTemplate,'base')
            setMailTo(myTo)
        }
        if(open && mail.EmailTemplate)
            getTo()
    },[open, mail])


    
   

    return (
        <>
        <Modal 
            open={open}           
            onClose={e=>setOpen(false)}
        >
            <Modal.Content>
                <Segment>
                    <Header as='h4' content='Empfaenger'/>
                    {
                        mailTo.map(e=>(
                            <Label color={e.Status==='gesendet' ? 'green': 'grey'}>
                                {`${e.Name} <${e.email}>` }
                            </Label>


                        ))
                    }
                </Segment>
                <Header as="h3">Betreff</Header>
                <Segment>
                   {mail.Betreff}
                </Segment>
               <Header as="h3">Mail Text</Header>
               <Segment  dangerouslySetInnerHTML={{
                       __html:    mail.Text
                }}/>
 
                
            </Modal.Content>
            <Modal.Actions>

                <Button
                    negative
                    icon='close'
                    labelPosition='right'
                    content='Schliessen'
                    onClick={e=>setOpen(false)}
                />
               

            </Modal.Actions>
      
    </Modal>
                
 
    </>   
    )
    
}
export default ShowMail





