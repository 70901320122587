import React,{useState}  from 'react';
import {useCheckForm} from '@wollo-lib/wollo-react' 
import {useApi} from '@wollo-lib/kpe-context'
import {Button}  from 'semantic-ui-react'
import useShopId from '../Hooks/useShopId' 
import {v1 as uuid} from 'uuid'

import EditPaket from './EditPaket'

const NeuesPaket=(props)=>
{
    const { apiNextId, apiInsert} = useApi()
    const shopId=useShopId()
    const [paket,setPaket]=useState({
        Kunde:'',
        Dienst:'',
        Datum:Date.now()/1000,
        ShopId:shopId,
        Paketnummer:'',
        Status:'registriert',
        AbsenderK:'',
        UID:uuid(),
        Paketdienst:''
    })

    
    const {errors,checkSubmit,hasError}=useCheckForm(paket,
        [
          {name:'Kunde',error:'Kunde angegeben werden',test:((val)=>(val!==''))},
          {name:'Dienst',error:'Dienst muss gewählt werden',test:((val)=>(val!==''))},
          {name:'Paketnummer',error:'Paketnummer muss angegeben werden',test:((val)=>(val!==''))},
        ]
    )
    


    
    const submit=()=>
    {
           
        if(!checkSubmit())
            return
        apiInsert('/db/Paket/',paket)
        setPaket ({
            Kunde:'',
            Dienst:'',
            Datum:Date.now()/1000,
            ShopId:shopId,
            Paketnummer:'',
            Status:'registriert',
            AbsenderK:'',  
            UID:uuid(),
            Paketdienst:''
        })

    }


    return (
     <>
        <EditPaket
            errors={errors}
            paket={paket}
            setPaket={setPaket}
            shopId={shopId}
            header='Neues Paket manuell erfassen'
        />
        <Button
            content='speichern'
            icon='save'
            onClick={submit}
            color={hasError.current ? null : 'green'}
        />
    </>
    )
    

}
export default NeuesPaket
