import React,{ useState, useEffect, useRef }  from 'react';
import {Container,Button,Header,Grid,Dropdown} from 'semantic-ui-react'
import MyTabulator,{autoNumFormatter,dateFormatter, dateFilter, dateEditor,downloadDateAccessor} from '@wollo-lib/wollo-tabulator'
import useShopId from '../Hooks/useShopId' 
import {useApi,usePageSelect} from '@wollo-lib/kpe-context'
import Transaktion from '../ShowKunde/Transaktion'

const UserList=({})=>
{
    const {apiSelect}=useApi()
    const [transaktionSelected, setTransaktionSelected]=useState({})
    const [modalOpen, setModalOpen]= useState(false)

    const [transaktionen,setTransaktionen]=useState([])
    const [tage,setTage]=useState(100)

    const shopId=useShopId()

    const userList=useRef(null)
    
    

   
    const replaceMode=usePageSelect({select:`/db/Transaktionen/${shopId}`,urlPara:{tage:tage}, setData:setTransaktionen, pageSize:500})


 
    function cellClick (cell) 
    {
        let row=cell.getRow()
        if(cell.getField()!=='UID')
        {
            
            const transaktion=cell.getData()
            setTransaktionSelected(transaktion)
            setModalOpen(true)
        }
    }
 
   

    const columns=[
   
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        { title: 'Datum', field: 'Datum', width: 180, formatter:dateFormatter, formatterParams:{
                    format:{year:"numeric",month:"2-digit", day:"2-digit", hour:"2-digit", minute:"2-digit"}}, 
                     headerFilter:dateEditor, 
                headerFilterFunc:dateFilter, accessorDownload: downloadDateAccessor, accessorDownloadParams: {}}, 
        { title: 'Vorname', field: 'Vorname', width: 150,  headerFilter:"input"},
        { title: 'Nachname', field: 'Nachname', width: 150,  headerFilter:"input"},
        {title: 'Art', field: 'Typ', width: 120,  headerFilter:"input"}
        
    ]



    return(
    <>
        <Container>
             <Grid>
                <Grid.Column width={10}>
                    <Header as ="h1">Transaktionsliste&nbsp;&nbsp;&nbsp;  </Header>
                    
                </Grid.Column>
                <Grid.Column width={6}>
                   <span>letzte 
                       <Dropdown
                            selection 
                            inline 
                            options={[30,100,200,500,1000].map(val=>({text:val,key:val,value:val }))}
                            value={tage}
                            name='tageOpt'
                            onChange={(ev,{value})=>{
                                setTage(value)
                            }}
                        /> 
                    Tage</span>
                </Grid.Column>
            </Grid>
            

            <MyTabulator
                index='UID'
                columns={columns}
                headerFilterPlaceholder="Spalte filtern"
                data={transaktionen}
                onClick={cellClick}
                height="750px"
                tabRef={userList}
                compact="very"
                replaceMode={replaceMode}
                striped

        
             />
        </Container>
        <Transaktion
          open={modalOpen}
          setOpen={setModalOpen}
          kunde={{Vorname:transaktionSelected.Vorname, Nachname: transaktionSelected.Nachname}}
          setReload={()=>{}}
          transaktionUID={transaktionSelected.UID}
          ws={false}
        />
    </>
    )
}
export default UserList
